import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spacing } from '@reservamos/elements';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import usePurchase from 'hooks/store/usePurchase';
import ManualInvoicing from './ManualInvoicing';
import ProviderInvoicing from './ProviderInvoicing';
import BenefitsInvoicing from './BenefitsInvoicing';

/**
 * InvoicingProcess component
 *
 * This component handles the invoicing process based on the invoice process type.
 * It redirects to the billing URL if the process is automatic, otherwise it displays
 * the appropriate manual or provider invoicing components.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.billingEmail - The billing email address
 * @param {string} props.billingUrl - The billing URL
 * @param {string} props.rfc - The RFC (tax identification number)
 * @returns {JSX.Element} The rendered component
 */
function InvoicingProcess({ billingEmail, billingUrl, rfc }) {
  const { t } = useTranslation('invoicing');
  const { createdAt, token, selectedServiceLevel, billing } = usePurchase();

  const { billingProcess, urls: rawUrls } = billing;

  const urls = rawUrls?.filter(Boolean) || [];

  const rfc64 = encodeURIComponent(btoa(unescape(encodeURIComponent(rfc || ''))));
  const token64 = encodeURIComponent(btoa(unescape(encodeURIComponent(token || ''))));

  const url = `${billingUrl}?mprs=${rfc64}&cdafb=${token64}`;

  const forceManualProcess = moment(createdAt).isBefore(moment('2023-08-18'));

  useEffect(() => {
    if (billingProcess === 'auto') {
      window.location.href = url;
    }
  }, [billingProcess, url]);

  if (billingProcess === 'auto') return null;

  return (
    <>
      {billingProcess === 'manual' || forceManualProcess ? (
        <Spacing vertical alignItems="center">
          <ManualInvoicing billingEmail={billingEmail} />
        </Spacing>
      ) : (
        <Spacing flexGrow fullWidth justifyContent="center" isResponsive size="L">
          <Spacing vertical>
            <p className="text-primary-regular text-md font-bold">{t('provider.title')}</p>

            <Spacing vertical>
              <span className="text-sm">
                <Trans
                  t={t}
                  i18nKey="provider.description"
                  components={{
                    text: <span className="text-sm">placeholder</span>,
                    bold: <span className="font-bold">placeholder</span>,
                  }}
                />
              </span>
            </Spacing>

            <div className="w-full">
              {billingProcess === 'semi-manual' ? (
                <ManualInvoicing billingEmail={billingEmail} />
              ) : (
                <ProviderInvoicing urls={urls} token={token} />
              )}
            </div>
          </Spacing>

          <Spacing vertical justifyContent="space-between">
            <BenefitsInvoicing url={url} serviceLevel={selectedServiceLevel} />
          </Spacing>
        </Spacing>
      )}
    </>
  );
}

InvoicingProcess.propTypes = {
  billingEmail: PropTypes.string,
  billingUrl: PropTypes.string,
  rfc: PropTypes.string,
};

InvoicingProcess.defaultProps = {
  billingEmail: '',
  billingUrl: '',
  rfc: '',
};

export default InvoicingProcess;
