// @ts-check

import { kueskiPayBnplStrategy, aplazoBnplStrategy } from '../../methods/bnpl/strategies';
import bnplMethod from '../../methods/bnpl/Bnpl';

/**
 * @typedef {import('./Bnpl.js').Bnpl} Bnpl
 */

const engines = {
  kueski_pay: kueskiPayBnplStrategy,
  aplazo: aplazoBnplStrategy,
};

/**
 * Factory to create instances of BNPL with a given engine.
 * @returns {object} An object with the create method to instantiate BNPL.
 */
function BnplFactory() {
  /**
   * Create an instance of BNPL with the given engine.
   * @param {object} config - Configuration with the engine.
   * @param {string} config.engine - The payment engine to use.
   * @returns {Bnpl} An instance of BNPL configured with the appropriate strategy.
   */
  function create({ engine }) {
    const strategy = engines[engine];

    if (!strategy) {
      throw new Error(`BNPL strategy not found for engine: ${engine}`);
    }

    bnplMethod.setStrategy(strategy);
    return bnplMethod;
  }

  return { create };
}

const bnplFactory = BnplFactory();

export default bnplFactory;
