import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Link, Spacing, Text } from '@reservamos/elements';
import usePurchase from 'hooks/store/usePurchase';
import ModalNextHoursRange from 'components/ModalNextHoursRange';
import DownloadButton from '../atoms/DownloadButton';

/**
 * TicketSummary Component
 * Component that renders a summarized ticket information block. It displays details such as the title, date, departure, and arrival information, along with a downloadable button.
 * @component
 * @param {string} title - The title of the ticket.
 * @param {string} date - The date of the ticket.
 * @param {string} originTime - The departure time.
 * @param {string} originTerminal - The departure terminal.
 * @param {string} originCity - The city of departure.
 * @param {string} originLocation - The location link for departure (optional).
 * @param {string} destinationTime - The arrival time.
 * @param {string} destinationTerminal - The arrival terminal.
 * @param {string} destinationCity - The city of arrival.
 * @param {string} destinationLocation - The location link for arrival (optional).
 * @param {string} href - The URL for downloading the ticket.
 * @param {string} buttonLabel - The label for the download button (optional).
 * @returns {JSX.Element} - The rendered TicketSummary component.
 */
const TicketSummary = ({
  title,
  date,
  href,
  buttonLabel,
  originTime,
  originTerminal,
  originCity,
  originLocation,
  destinationTime,
  destinationTerminal,
  destinationCity,
  destinationLocation,
}) => {
  const { t } = useTranslation('purchase');
  const { bulkTicket: isBulkTicket } = usePurchase();

  return (
    <Spacing size="S" vertical>
      {title ? (
        <Spacing size="XS" alignItems="baseline">
          <Text size="L" weight="bold">
            {title}
          </Text>
          <Text size="M">{date}</Text>
        </Spacing>
      ) : null}

      <Spacing vertical>
        {isBulkTicket && <ModalNextHoursRange />}

        <Box
          alphaBg="XS"
          bgColor="grayMedium"
          borderRadius="M"
          paddingHorizontal="M"
          paddingVertical="M"
        >
          <Spacing size="XS" responsiveSize="M" vertical>
            <Spacing size="XXS" responsiveSize="S" justifyContent="space-between" isResponsive>
              <Spacing alignItems="center">
                <Spacing size="XXS" vertical>
                  <Spacing size="S">
                    <Text size="XS">
                      {t('label.departure')}: <b>{originTime}</b>
                    </Text>
                  </Spacing>
                  <Text weight="semibold" size="L" mobileSize="M">
                    {`${originTerminal} `}
                  </Text>
                  <Text size="XS">
                    {originCity}{' '}
                    {originLocation && (
                      <Link
                        type="primary"
                        text={t('label.view_location')}
                        href={originLocation}
                        newTab
                      />
                    )}
                  </Text>
                </Spacing>

                <Spacing size="XXS" vertical>
                  <Spacing size="XS">
                    <Text size="XS">
                      {t('label.arrival')}: <b>{destinationTime}</b>
                    </Text>
                  </Spacing>
                  <Text weight="semibold" size="M" mobileSize="S">
                    {`${destinationTerminal} `}
                  </Text>
                  <Text size="XS">
                    {destinationCity}{' '}
                    {destinationLocation && (
                      <Link
                        type="primary"
                        text={t('label.view_location')}
                        href={destinationLocation}
                        newTab
                      />
                    )}
                  </Text>
                </Spacing>
              </Spacing>

              <div className="flex items-center justify-center">
                <DownloadButton label={buttonLabel} href={href} />
              </div>
            </Spacing>
          </Spacing>
        </Box>
      </Spacing>
    </Spacing>
  );
};

TicketSummary.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  originTime: PropTypes.string.isRequired,
  originTerminal: PropTypes.string.isRequired,
  originCity: PropTypes.string.isRequired,
  originLocation: PropTypes.string.isRequired,
  destinationTime: PropTypes.string.isRequired,
  destinationTerminal: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  destinationLocation: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
};

TicketSummary.defaultProps = {
  buttonLabel: '',
};

export default TicketSummary;
