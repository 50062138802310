import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { camelizeKeys } from 'humps';
import * as types from 'constants/ActionTypes';
import { setError } from '@/actions';
import PropTypes from 'prop-types';
import { Button, DialogMessage, Text, Alert } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { executeRecaptcha } from 'utils/googleRecaptcha';
import TicketListBlock from './TicketsListBlock';

/**
 * ExchangeTicketList Functional Component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.currentTicket - The current ticket details.
 * @param {Function} props.validateExchange - The function to validate the ticket exchange.
 * @param {Array} props.allPassengers - The list of all passengers.
 * @param {Object} props.tripReturn - The return trip details.
 * @param {Object} props.origin - The origin location details.
 * @param {Object} props.destination - The destination location details.
 * @param {Object} props.tripDepart - The departure trip details.
 * @param {Object} props.selectedPassengerToAlter - The selected passenger to alter the ticket for.
 * @param {Function} props.cancelTicket - The function to cancel a ticket.
 * @param {Function} props.getCancelledTicketStatusPoll - The function to poll the status of a cancelled ticket.
 * @param {string} props.purchaseToken - The token used for ticket purchase.
 * @param {Object} props.cancelStatus - The status of the ticket cancellation process.
 * @param {Boolean} props.hasConnections - If this trip has connections
 * @param {Array} props.tripDepartConnection - The departure trip detail when trip has connections
 * @param {Array} props.tripReturnConnection - The return trip detail when trip has connections
 *
 * @returns {JSX.Element} The rendered ticket exchange component.
 */
const ExchangeTicketList = ({
  currentTicket,
  validateExchange,
  allPassengers,
  tripReturn,
  origin,
  destination,
  tripDepart,
  selectedPassengerToAlter,
  cancelTicket,
  getCancelledTicketStatusPoll,
  purchaseToken,
  cancelStatus,
  hasConnections,
  hasConnectionsReturns,
  tripDepartConnection,
  tripReturnConnection,
}) => {
  const { t } = useTranslation('exchange');
  const features = useWhitelabelFeatures();
  const env = useWhitelabelEnvs();
  const showCieloPixWarning = features.SHOW_CIELO_PIX_WARNING_ON_EXCHANGE;
  const [showCancelModal, setShowCancelModal] = useState(false);

  const history = useHistory();
  const { operationNumbers, email, document } = useParams();

  const dispatch = useDispatch();
  const usingRecaptcha = features.EXCHANGE_REQUIRE_RECAPTCHA && env.recaptcha;

  const validateExchangeLocal = useCallback(() => {
    /**
     * Function to handle the recaptcha token
     */
    const onCaptchaVerify = (onRecaptchaToken) => {
      const action = features.EXCHANGE_RECAPTCHA_ACTION;
      executeRecaptcha(action, (recaptchaToken) => {
        onRecaptchaToken(recaptchaToken);
      });
    };

    if (currentTicket === '' || cancelStatus === 'success') {
      dispatch({ type: types.STATUS_CANCEL_RESET });
      const operationNumbersArray = operationNumbers.split(',');

      /**
       * Function to validate the exchange wrapping the recaptcha token if needed
       * @param {*} [recaptchaToken] - Recaptcha token
       * @returns {function} - Dispatch function
       */
      const validationFunction = (recaptchaToken) =>
        validateExchange(
          history,
          operationNumbersArray,
          '',
          '',
          '',
          recaptchaToken,
          email,
          document,
        );

      if (usingRecaptcha) {
        onCaptchaVerify(validationFunction);
      } else {
        validationFunction();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * getOnClickedTicket Function
   *
   * Returns a function that handles clicking on a specific ticket.
   *
   * @param {string} nodeTripName - The name of the trip node (e.g., depart or return).
   * @returns {Function} A function that takes a ticketKey and performs an action on the clicked ticket.
   *
   */
  const getOnClickedTicket = (nodeTripName) => (ticketKey) => {
    dispatch({
      type: types.EXCHANGE_SELECTED_PASSENGER_TO_ALTER,
      selectedPassengerToAlter: {
        ticketKey,
        nodeTripName,
      },
    });
  };

  /**
   * handleExchangeSelectedTickets Function
   *
   * Handles the process of exchanging the selected tickets.
   *
   * This function is typically called when the user confirms the exchange of tickets,
   * and performs the necessary logic to process the exchange.
   *
   * @returns {void}
   */
  const handleExchangeSelectedTickets = () => {
    if (selectedPassengerToAlter.ticketKey !== '') {
      const allPassengersGrouped = [...allPassengers.depart, ...allPassengers.return];
      const nodeTripName =
        selectedPassengerToAlter.nodeTripName === 'depart' ? tripDepart : tripReturn;

      const selectedPassengers = allPassengersGrouped.filter((passenger) => {
        return passenger.transporterKey === selectedPassengerToAlter.ticketKey;
      });

      dispatch({
        type: types.EXCHANGE_UPDATE_PASSENGERS,
        passengers: selectedPassengers,
        trip: nodeTripName,
      });

      history.push(`/exchange/schedule/${operationNumbers}/${origin}/${destination}`);
    } else {
      dispatch(
        setError(200, 'select_a_ticket', 'warning', false, 'Selecione um ingresso por favor'),
      );
    }
  };

  useEffect(() => {
    // Run validateExchangeLocal on mount
    validateExchangeLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * handleCancelSelectedTickets Function
   *
   * Handles the process of cancelling the selected tickets.
   *
   * This function is called when the user chooses to cancel one ticket,
   * and performs the necessary logic to process the cancellation.
   *
   * @returns {void}
   */
  const handleCancelSelectedTickets = () => {
    if (selectedPassengerToAlter.ticketKey !== '') {
      setShowCancelModal(true);
    } else {
      dispatch(
        setError(200, 'select_a_ticket', 'warning', false, 'Selecione um ingresso por favor'),
      );
    }
  };

  /**
   * handleCancelModalQuit Function
   *
   * Handles the closing or quitting of the cancel ticket modal.
   *
   * This function is called when the user decides to exit the modal without completing
   * the cancellation process, and it performs the necessary actions to close or clean up the modal state.
   *
   * @returns {void}
   */
  const handleCancelModalQuit = () => {
    setShowCancelModal(false);
  };

  /**
   * handleCancelModalConfirm Function
   *
   * Handles the confirmation of ticket cancellation within the modal.
   *
   * This async function is called when the user confirms the cancellation of selected tickets
   * in the modal. It processes the cancellation and performs any necessary asynchronous operations.
   *
   * @async
   * @returns {Promise<void>} A promise that resolves when the cancellation process is completed.
   */
  const handleCancelModalConfirm = async () => {
    if (selectedPassengerToAlter.ticketKey !== '') {
      const id = await cancelTicket(purchaseToken, [selectedPassengerToAlter.ticketKey]);
      await getCancelledTicketStatusPoll(purchaseToken, id);
      if (cancelStatus === 'success') {
        validateExchangeLocal();
      }
    }
    setShowCancelModal(false);
  };

  return (
    <>
      <div>
        <h2 className="text-xl font-bold">{t('ticket_list.title')}</h2>
        <p className="text-gray-600 m-0">{t('ticket_list.subtitle')}</p>
      </div>
      {Object.keys(allPassengers.depart).length !== 0 && (
        <div className="flex flex-col md:flex-row gap-4">
          <TicketListBlock
            trip={tripDepart}
            passengers={allPassengers.depart}
            getOnClickedTicket={getOnClickedTicket('depart')}
          >
            {t('ticket_list.one_way_trip')}
          </TicketListBlock>
        </div>
      )}
      {hasConnections && tripDepartConnection.length !== 0 && (
        <div>
          <p className="mb-2 text-sm">
            <span className="mt-0 block">
              <strong>{t('ticket_list.travel_connection')}</strong>
            </span>
            {t('ticket_list.one_way_trip')}
            <span className="ml-4">
              {tripDepart.originDisplay} - {tripDepart.destinationDisplay}
            </span>
          </p>
          <div className="border-b border-2 rounded-md p-5">
            {tripDepartConnection.map((connection, index) => (
              <div key={connection.origin}>
                <p className="mt-5">
                  <strong>
                    {t('ticket_list.connection')} {index + 1}
                  </strong>
                </p>
                {/* Customize the HTML content here */}
                <TicketListBlock
                  trip={{
                    originDisplay: connection.origin,
                    originSlug: connection.origin_slug,
                    destinationDisplay: connection.destination,
                    destinationSlug: connection.destination_slug,
                    date: connection.search_date,
                    time: connection.time,
                  }}
                  passengers={camelizeKeys(connection.tickets)}
                  getOnClickedTicket={getOnClickedTicket('depart')}
                >
                  {t('ticket_list.one_way_trip')}
                </TicketListBlock>
              </div>
            ))}
          </div>
        </div>
      )}
      {Object.keys(allPassengers.return).length !== 0 && (
        <div className="flex flex-col md:flex-row gap-4">
          <TicketListBlock
            trip={tripReturn}
            passengers={allPassengers.return}
            getOnClickedTicket={getOnClickedTicket('return')}
          >
            {t('ticket_list.ride_back')}
          </TicketListBlock>
        </div>
      )}
      {hasConnectionsReturns && tripReturnConnection.length !== 0 && (
        <div>
          <p className="mb-2 text-sm">
            <span className="mt-0 block">
              <strong>{t('ticket_list.travel_connection')}</strong>
            </span>
            {t('ticket_list.ride_back')}
            <span className="ml-4">
              {tripReturn.originDisplay} - {tripReturn.destinationDisplay}
            </span>
          </p>
          <div className="border-b border-2 rounded-md p-5">
            {tripReturnConnection.map((connection, index) => (
              <div key={connection.origin}>
                <p className="mt-5">
                  <strong>
                    {t('ticket_list.connection')} {index + 1}
                  </strong>
                </p>
                {/* Customize the HTML content here */}
                <TicketListBlock
                  trip={{
                    originDisplay: connection.origin,
                    originSlug: connection.origin_slug,
                    destinationDisplay: connection.destination,
                    destinationSlug: connection.destination_slug,
                    date: connection.search_date,
                    time: connection.time,
                  }}
                  passengers={camelizeKeys(connection.tickets)}
                  getOnClickedTicket={getOnClickedTicket('depart')}
                >
                  {t('ticket_list.ride_back')}
                </TicketListBlock>
              </div>
            ))}
          </div>
        </div>
      )}

      {showCieloPixWarning && (
        <Alert alertType="warning" padding="S">
          <Text>{t('ticket_list.pix_warning')}</Text>
        </Alert>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <p className="text-xs text-gray-400 pr-8">
          *{' '}
          {features.SHOW_BOTTOM_TEXT_EXTENDED
            ? t('ticket_list.bottom_text_extended')
            : t('ticket_list.bottom_text')}
        </p>
        <div className="flex justify-end space-x-2 gap-4">
          {features.SHOW_EXCHANGE_TICKET_BUTTON && (
            <Button
              buttonType="submit"
              text={t('ticket_list.button_change')}
              variant="noFill"
              isRounded
              withHeight
              fullWidthOnSmall
              className="inline-flex w-auto"
              onClick={handleExchangeSelectedTickets}
            />
          )}
          {features.SHOW_CANCEL_TICKET_BUTTON && (
            <Button
              buttonType="submit"
              text={t('ticket_list.button_cancel')}
              variant="accent"
              isRounded
              withHeight
              fullWidthOnSmall
              className="inline-flex w-auto"
              onClick={handleCancelSelectedTickets}
            />
          )}
        </div>
      </div>
      {showCancelModal && (
        <DialogMessage
          onClickPrimary={() => handleCancelModalConfirm()}
          onClickSecondary={() => handleCancelModalQuit()}
          primaryButton={t('modal_confirm_cancellation.primary_button')}
          secondaryButton={t('modal_confirm_cancellation.secondary_button')}
          title={t('modal_confirm_cancellation.title')}
        >
          <Text>{t('modal_confirm_cancellation.first_text')}</Text>
          {features.SHOW_EXCHANGE_DISCLAIMER && (
            <Text>{t('modal_confirm_cancellation.second_text')}</Text>
          )}
          {showCieloPixWarning && (
            <div className="mt-6">
              <Alert alertType="warning" padding="S">
                <Text>{t('ticket_list.pix_warning')}</Text>
              </Alert>
            </div>
          )}
        </DialogMessage>
      )}
    </>
  );
};

ExchangeTicketList.propTypes = {
  currentTicket: PropTypes.string.isRequired,
  validateExchange: PropTypes.func,
  allPassengers: PropTypes.object,
  tripReturn: PropTypes.object,
  origin: PropTypes.string,
  destination: PropTypes.string,
  tripDepart: PropTypes.object,
  selectedPassengerToAlter: PropTypes.object,
  cancelTicket: PropTypes.func,
  getCancelledTicketStatusPoll: PropTypes.func,
  purchaseToken: PropTypes.string,
  cancelStatus: PropTypes.string,
  hasConnections: PropTypes.bool,
  hasConnectionsReturns: PropTypes.bool,
  tripDepartConnection: PropTypes.array,
  tripReturnConnection: PropTypes.array,
};

export default ExchangeTicketList;
