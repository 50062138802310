import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@reservamos/elements';
import { formatPassengerName } from 'utils/userPreferences';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useFrequentPassengersContext from '../context/FrequentPassengers';
import passengerTypes from '../config/passengerTypes';

/**
 * FrequentPassengerNav component.
 * Renders a horizontal scrollable list of frequent passenger buttons for quick selection.
 * @param {object} props - passengerIndex
 * @param {number} props.passengerIndex - The index of the current passenger being selected
 */
const FrequentPassengerNav = ({ passengerIndex }) => {
  const { frequentPassengers, selectFrequentPassenger } = useFrequentPassengersContext();
  const features = useWhitelabelFeatures();
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  return (
    <div className="flex w-full overflow-x-scroll whitespace-nowrap space-x-2 border-r border-r-gray-200 py-1">
      {frequentPassengers.map((passenger) => {
        const { icon, iconVariant } = passengerTypes[passenger.category] || {};

        return (
          <Button
            key={passenger.id}
            iconType={isFlat ? iconVariant : icon}
            padding="S"
            mobileSize="XS"
            text={formatPassengerName({
              firstName: passenger.firstName,
              lastName: passenger.lastName,
              initialOnly: true,
            })}
            colorActiveSelection={
              passengerIndex === passenger.selectedForPassenger && passenger.isSelected
                ? 'successStrong'
                : undefined
            }
            alphaBg={
              passengerIndex === passenger.selectedForPassenger && passenger.isSelected
                ? undefined
                : '35'
            }
            onClick={() => selectFrequentPassenger(passenger.id, passengerIndex, 'List')}
            isRounded
            minWidth={false}
          />
        );
      })}
    </div>
  );
};

FrequentPassengerNav.propTypes = {
  passengerIndex: PropTypes.number.isRequired,
};

export default FrequentPassengerNav;
