/**
 * @typedef {import('./WalletStrategy').WalletStrategy} WalletStrategy
 * @typedef {import('./WalletStrategy').WalletInfo} WalletInfo
 * @typedef {import('./WalletStrategy').EnginePayload} EnginePayload
 */

/**
 * MercadoPago wallet strategy.
 * @function MercadoPagoWalletStrategy
 * @returns {WalletStrategy}
 */
function MercadoPagoWalletStrategy() {
  /**
   * Creates the payload for the MercadoPago wallet payment.
   * @param {WalletInfo} walletInfo - Wallet information
   * @param {EnginePayload} enginePayload - Additional payload specific to the engine
   * @returns {Promise<Object>} The created payload
   */
  const createPayload = async (walletInfo, enginePayload) => {
    return {
      payment_type: walletInfo.payment_type,
      payment_engine: walletInfo.payment_engine,
      payment_provider: walletInfo.payment_provider,
      tracker_id: walletInfo.tracker_id,
      device_session_id: walletInfo.device_session_id,
      monthly_selected_plan: walletInfo.monthly_selected_plan,
      ...enginePayload,
    };
  };

  return { createPayload };
}

const mercadoPagoWalletStrategy = MercadoPagoWalletStrategy();

export default mercadoPagoWalletStrategy;
