const getSeats = (layout, tripSlug) => {
  const seats = {};
  const categories = new Set();
  let seatIndex = 0;

  layout.flat(2).forEach(({ number, seatLevel, category, seatFareDescription, ...rest }) => {
    if (!number) return;

    seatIndex += 1;

    const seatKey = seatLevel === '2' ? seatIndex : number;
    seats[seatKey] = {
      number,
      seatLevel,
      category,
      tripSlug,
      index: seatIndex,
      seatFareDescription,
      ...rest,
    };

    categories.add(category);
  });

  seats.categories = Array.from(categories);
  return seats;
};

export default getSeats;
