import React, { useState } from 'react';
import { Button, Icon, Input, Text, Tooltip } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import tokenReference from 'images/brands/reservamos/token-reference.jpg';

/**
 * InvoicingForm component
 *
 * This component renders a form for invoicing, allowing the user to input a token.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
function InvoicingForm() {
  const [token, setToken] = useState('');
  const { t } = useTranslation('invoicing');

  /**
   * Handles the change event for the token input field.
   *
   * @param {Object} event - The event object
   */
  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  /**
   * Handles the form submission.
   *
   * @param {Object} event - The event object
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.search = `?token=${encodeURIComponent(token)}`;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mx-auto flex flex-col md:flex-row items-center max-w-[400px] md:max-w-none gap-5 justify-center">
        <div className="flex items-center [&>div:first-child]:flex-grow [&>div:first-child]:mr-2 [&>div>div:first-child]:mt-0 w-full md:w-1/3">
          <Input
            id="token"
            name="token"
            type="text"
            value={token}
            onChange={handleTokenChange}
            label={t('form.placeholder')}
          />

          <Tooltip
            type="left"
            variant="light"
            widthBox={286}
            customContent={
              <div>
                <Text size="XS" weight="semibold">
                  {t('form.tooltip')}
                </Text>
                <img className="w-[275px]" src={tokenReference} />
              </div>
            }
          >
            <Icon size="L" type="badgeQuestion" />
          </Tooltip>
        </div>

        <div className="md:m-0 md:max-w-fit mt-3 max-w-full w-2/3">
          <Button
            fullWidth
            variant="accent"
            text={t('form.button')}
            minHeight="45px"
            buttonType="submit"
          />
        </div>
      </div>
    </form>
  );
}

export default InvoicingForm;
