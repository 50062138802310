import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import ServiceLevelOption from './ServiceLevelOption';

/**
 * ServiceLevels component.
 *
 * @param {object} props - updateServiceLevel, isUpdating, selectedServiceLevel, serviceLevels, token
 * @param {Function} props.updateServiceLevel - Function to update the service level
 * @param {boolean} props.isUpdating - Flag indicating if the service level is being updated
 * @param {object} props.selectedServiceLevel - The currently selected service level
 * @param {Array<object>} props.serviceLevels - List of available service levels
 * @param {string} props.token - Authentication token
 * @returns {JSX.Element} The rendered component
 */
const ServiceLevels = ({
  updateServiceLevel,
  isUpdating,
  selectedServiceLevel,
  serviceLevels,
  token,
}) => {
  const { t } = useTranslation('purchase');

  const { maxLevel, basicLevel } = useMemo(
    () => ({
      maxLevel: serviceLevels.find((sl) => sl.name !== 'basic'),
      basicLevel: serviceLevels.find((sl) => sl.name === 'basic'),
    }),
    [serviceLevels],
  );

  /**
   * Handles the change event for the service level option
   *
   * @param {Object} element - The event object
   */
  const handleChange = useCallback(
    (element) => {
      if (!maxLevel || !basicLevel) {

        return;
      }

      if (element.target.checked) {
        updateServiceLevel(token, maxLevel.id);
      } else {
        updateServiceLevel(token, basicLevel.id);
      }
    },
    [token, updateServiceLevel, maxLevel, basicLevel],
  );

  if (!maxLevel || !basicLevel) return null;

  return (
    <Spacing vertical size="M">
      <Spacing vertical size="XS">
        <Text weight="bold">{t('service-levels.title')}</Text>
        <Text mobileSize="S" color="grayMedium" elementType="span">
          <Spacing vertical size="XS">
            {t('service-levels.subtitle')}
            <Link
              href="https://contenido.reservamos.mx/cobertura-boletoflex/"
              text={t('service-levels.details-link')}
              type="info"
              weight="semibold"
              size="S"
              newTab
            />
          </Spacing>
        </Text>
      </Spacing>

      <Spacing justifyContent="space-between" alignItems="flex-start" isResponsive>
        <ServiceLevelOption coverage="basic" />
        <ServiceLevelOption
          coverage="max"
          price={maxLevel ? Number(maxLevel.price || 0) - Number(basicLevel?.price || 0) : 0}
          isChecked={selectedServiceLevel?.name !== 'basic'}
          isUpdating={isUpdating}
          handleChange={handleChange}
        />
      </Spacing>
      <Text weight="bold" color="grayMedium" size="S" italic>
        {t('service-levels.terms')}
      </Text>
    </Spacing>
  );
};

ServiceLevels.propTypes = {
  updateServiceLevel: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  selectedServiceLevel: PropTypes.shape({
    name: PropTypes.string,
  }),
  serviceLevels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      price: PropTypes.string,
    }),
  ),
  token: PropTypes.string,
};

ServiceLevels.defaultProps = {
  selectedServiceLevel: null,
  serviceLevels: [],
  token: '',
};

export default ServiceLevels;
