// @ts-check

/**
 * @typedef {import('./strategies/WalletStrategy').WalletStrategy} WalletStrategy
 * @typedef {import('./strategies/WalletStrategy').WalletInfo} WalletInfo
 * @typedef {import('./strategies/WalletStrategy').EnginePayload} EnginePayload
 */

/**
 * Wallet payment method.
 * @function Wallet
 */
const Wallet = () => {
  /**
   * @type {WalletStrategy | undefined}
   */
  let strategy;

  /**
   * Sets the wallet strategy.
   * @param {WalletStrategy} newStrategy - The new wallet strategy.
   */
  const setStrategy = (newStrategy) => {
    strategy = newStrategy;
  };

  /**
   * Creates the payload for the wallet payment.
   * @param {WalletInfo} walletInfo - Wallet information.
   * @param {EnginePayload} enginePayload - Additional payload specific to the engine.
   * @returns {Promise<Object>} The created payload.
   */
  const createPayload = async (walletInfo, enginePayload = {}) => {
    if (!strategy) {
      throw new Error('Wallet strategy not set');
    }

    return strategy.createPayload(walletInfo, enginePayload);
  };

  return {
    setStrategy,
    createPayload,
  };
};

const wallet = Wallet();

export default wallet;
