import React from 'react';
import { Spacing, Text, Modal } from '@reservamos/elements';
import CSSVariablesProvider from 'components/CSSVariablesProvider';
import { useTranslation } from 'react-i18next';
import TicketItinerary from 'components/purchase/TicketItinerary';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import PropTypes from 'prop-types';

/**
 * ModalItinerary component.
 * Renders a modal containing trip itinerary details
 * @param {object} props - modalTrigger, text
 * @param {ReactNode} props.modalTrigger - Element that triggers the modal
 * @param {string} props.text - Text to display above the modal trigger
 */
const ModalItinerary = ({ modalTrigger, text }) => {
  const { t } = useTranslation('purchase');
  const { theme, brand } = useWhitelabelEnvs();
  const { FUNNEL_STYLE } = useWhitelabelFeatures();

  return (
    <Spacing vertical size="S">
      <Text weight="bold">{text}</Text>
      <Modal title={t('title.trip_details')} modalTrigger={modalTrigger} responsiveSize="L">
        <CSSVariablesProvider
          theme={theme}
          funnelStyle={FUNNEL_STYLE}
          funnelStyleBrandVariation={brand.toUpperCase()}
        >
          <div className="trip-summary-itinerary-purchase-complete">
            <TicketItinerary showSeats={false} showDownloadTicketsStep />
          </div>
        </CSSVariablesProvider>
      </Modal>
    </Spacing>
  );
};

ModalItinerary.propTypes = {
  modalTrigger: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default ModalItinerary;
