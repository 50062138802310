import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  description: PropTypes.string.isRequired,
  hasNextDayBadge: PropTypes.bool,
};

/**
 * TransferItem component.
 * @param {Object} props - Component props
 * @param {string} props.description - Transfer description
 * @param {boolean} props.hasNextDayBadge - Indicates if any segment has a next day badge
 */
const TransferItem = ({ description, hasNextDayBadge }) => (
  <div className="route-detail-row transfer">
    <div className={`rd-left ${hasNextDayBadge ? 'with-next-day' : ''}`} />

    <div className="rd-center">
      <i className="ic-transfer" />
    </div>

    <div className="rd-right bus">
      <p>
        <span>{description}</span>
      </p>
    </div>
  </div>
);

TransferItem.propTypes = propTypes;

export default TransferItem;
