import React from 'react';
import { IconText } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import card from '../../images/payment/mercado-pago/card.png';
import store from '../../images/payment/mercado-pago/store.png';
import cash from '../../images/payment/mercado-pago/cash.png';
import phone from '../../images/payment/mercado-pago/phone.png';
import './PaymentBenefits';

const MercadoPagoIcons = () => {
  const { t } = useTranslation('payment');
  const benefits = [
    {
      img: card,
      text: t('mercadopago_benefits.first'),
    },
    {
      img: store,
      text: t('mercadopago_benefits.second'),
    },
    {
      img: cash,
      text: t('mercadopago_benefits.third'),
    },
    {
      img: phone,
      text: t('mercadopago_benefits.fourth'),
    },
  ];

  return (
    <div className="payment-benefits-grid">
      {benefits.map((data) => (
        <IconText key={data.img} iconSize="L" label={data.text} iconType={data.img} fontSize="S" />
      ))}
    </div>
  );
};

export default MercadoPagoIcons;
