import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import usePurchase from 'hooks/store/usePurchase';
import { Box, Icon, Spacing, Text } from '@reservamos/elements';
import HourTag from 'ui/atoms/HourTag';
import { processNextDepartures } from 'utils/purchase/timer';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

/**
 * NextHoursRange component.
 * Renders a range of departure times with start and end indicators.
 * @param {object} props - border, padding, icon
 * @param {boolean} props.border - Shows border around component
 * @param {boolean} props.padding - Adds horizontal padding
 * @param {boolean} props.icon - Shows chevron icon at the end
 */
const NextHoursRange = ({ border, padding, icon }) => {
  const { t } = useTranslation();
  const { departures } = usePurchase();
  const { SHOW_NEXT_DEPARTURES } = useWhitelabelFeatures();

  const timeSlots = processNextDepartures(departures?.trips);

  if (!timeSlots?.length) return null;

  if (!SHOW_NEXT_DEPARTURES) return null;

  return (
    <Box
      paddingHorizontal={padding ? 'S' : ''}
      paddingVertical="XS"
      borderRadius="L"
      border={border ? 'all' : ''}
      borderColor="grayBorder"
      fullWidthOnSmall
    >
      <Spacing justifyContent="space-between" responsiveSize="XXS">
        <Text size="S" xsMobileSize="XXS">
          {t('general:next_departures')}
        </Text>

        <Spacing alignItems="center" justifyContent="space-between">
          <HourTag icon={timeSlots[0].period} label={timeSlots[0].time} bgColor="transparent" />

          <Text weight="semibold" size="XXS" color="grayMedium">
            {t('general:next_departures_range')}
          </Text>

          <Spacing size="XS" alignItems="center">
            <Icon type="Morning" size="S" color="grayMedium" />
            <HourTag
              icon={timeSlots[timeSlots.length - 1].period}
              label={timeSlots[timeSlots.length - 1].time}
              bgColor="transparent"
            />
            {icon && <Icon type="ChevronRight" size="S" color="grayMedium" />}
          </Spacing>
        </Spacing>
      </Spacing>
    </Box>
  );
};

NextHoursRange.propTypes = {
  border: PropTypes.bool,
  padding: PropTypes.bool,
  icon: PropTypes.bool,
};

NextHoursRange.defaultProps = {
  border: false,
  padding: false,
  icon: false,
};

export default NextHoursRange;
