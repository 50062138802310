import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spacing, Text, Link } from '@reservamos/elements';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Trans, useTranslation } from 'react-i18next';
import usePurchase from 'hooks/store/usePurchase';
import { getPurchase } from '@/actions/purchase';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import LoadingScreen from '../LoadingScreen';
import Header from '../Header';
import BrandLogo from '../BrandLogo';
import InvoicingProcess from './InvoicingProcess';
import InvoicingForm from './InvoicingForm';

/**
 * Invoicing component
 *
 * This component handles the invoicing process. It fetches the purchase data
 * if a token is present in the URL and displays the appropriate form or process
 * based on the loaded state.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
const Invoicing = () => {
  const env = useWhitelabelEnvs();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const { loaded } = usePurchase();
  const { t } = useTranslation('invoicing');
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');

  const { email: billingEmail, url: billingUrl, rfc } = env.billing;

  const invoiceBullets = [1, 2, 3];
  const noteBullets = [1, 2];
  const reminderBullets = [1, 2];

  useEffect(() => {
    if (token && !loaded) {
      dispatch(getPurchase(token));
    }
  }, [dispatch, loaded, token]);

  if (!loaded && token) return <LoadingScreen />;

  return (
    <div className="l-purchase bg-gray-200">
      <Header isFixed>
        <BrandLogo />
      </Header>

      <div className="home-container">
        <Spacing vertical size="XL">
          <Text color="grayDark" size="XXL" weight="bold" textAlign="left">
            {t('title')}
          </Text>
          <Spacing vertical>
            <div className="bg-white rounded-lg shadow-md p-6">
              <Text weight="bold">{t('disclaimer.header')}</Text>
              <ul className="mt-4">
                <Spacing size="XS" vertical>
                  {invoiceBullets.map((bullet) => (
                    <li key={bullet} className="mb-2">
                      <Trans
                        t={t}
                        i18nKey="disclaimer.bullet"
                        tOptions={{ context: String(bullet) }}
                      />
                    </li>
                  ))}
                </Spacing>
              </ul>
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              <Spacing vertical>
                <div className="bg-white rounded-lg shadow-md p-6">
                  <Text weight="bold">{t('note.header')}</Text>
                  <ul>
                    <Spacing size="S" vertical>
                      {noteBullets.map((bullet) => (
                        <li key={bullet}>
                          <Spacing alignItems="center">
                            <Trans
                              t={t}
                              i18nKey="note.bullet"
                              tOptions={{ context: String(bullet) }}
                              components={{
                                text: <Text size="M">placeholer</Text>,
                                link: (
                                  <Link
                                    href={`mailto:${billingEmail}`}
                                    text={billingEmail}
                                    type="accent"
                                    weight="semibold"
                                    size="M"
                                    newTab
                                  />
                                ),
                              }}
                            />
                          </Spacing>
                        </li>
                      ))}
                    </Spacing>
                  </ul>
                </div>
              </Spacing>
              <Spacing vertical>
                <div className="bg-white rounded-lg shadow-md p-6 min-h-full">
                  <Text weight="bold">{t('reminder.header')}</Text>
                  <ul>
                    <Spacing size="S" vertical>
                      {reminderBullets.map((bullet) => (
                        <li key={bullet}>
                          <Trans
                            t={t}
                            i18nKey="reminder.bullet"
                            tOptions={{ context: String(bullet) }}
                          />
                        </li>
                      ))}
                    </Spacing>
                  </ul>
                </div>
              </Spacing>
            </div>
          </Spacing>
          <div className="bg-white rounded-lg shadow-md p-6">
            {token ? (
              <InvoicingProcess billingEmail={billingEmail} billingUrl={billingUrl} rfc={rfc} />
            ) : (
              <InvoicingForm />
            )}
          </div>
          {token && (
            <button
              type="button"
              className="self-center inline-block px-4 py-2 mt-4 text-sm font-medium text-white bg-primary-regular rounded-md hover:bg-primary-strong max-w-fit"
              onClick={() => {
                const path = history.location.pathname;
                history.push(path);
              }}
            >
              {t('form.issue_new_invoice')}
            </button>
          )}
        </Spacing>
      </div>
    </div>
  );
};

export default Invoicing;
