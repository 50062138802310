import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePurchase from 'hooks/store/usePurchase';
import { Button, Link, Spacing, Text } from '@reservamos/elements';
import Header from 'components/Header';
import Loading from 'components/Loading';
import { getPurchase } from '@/actions/purchase';
import EvertecPaymentDetails from 'components/purchase/EvertecPaymentDetails';
import BrandLogo from '../../BrandLogo';
import useReactNativeMessage from '../../../hooks/useReactNativeMessage';
import 'styles/components/payment/FailedPayment';
import PurchaseLayout from '../../../ui/layouts/PurchaseLayout';
import PaymentStatusIcon from '../../purchase/PaymentStatusIcon';
import CompleteHeader from '../../../ui/atoms/CompleteHeader';

/**
 * CanceledPayment Component
 * Displays the UI for a canceled payment scenario within the application.
 * It provides options to retry the payment if it has not expired and shows contact information.
 * Uses React hooks for managing state and side effects:
 * - useState to manage the 'expired' state indicating if the payment retry period has expired.
 * - useEffect for fetching purchase details, posting messages to a mobile app, and checking expiration.
 * Redirects to a 404 page if the loaded payment engine is not 'evertec'.
 * @returns {JSX.Element} The rendered component with conditional rendering based on the payment status and expiration.
 */
const CanceledPayment = () => {
  const { t } = useTranslation();
  const purchase = usePurchase();
  const [expired, setExpired] = useState(false);
  const {
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);
  const { phones, email } = provider;
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();
  const RNMessage = useReactNativeMessage();
  const { loaded, paymentEngine } = purchase;
  const phoneItems = phones
    .map((phone) => (
      <a href={`tel:${phone.value}`} key={phone.value}>
        {phone.display}
      </a>
    ))
    .reduce((links, link) => (links ? [...links, ', ', link] : [link]), null);

  useEffect(() => {
    if (loaded && purchase.state !== 'canceled' && paymentEngine !== 'evertec') {
      history.replace('/404');
    }
  }, [loaded, purchase.state, paymentEngine, history]);

  const retryPayment = () => {
    history.push(`/purchase/${token}/checkout`);
  };

  useEffect(() => {
    if (!loaded) getPurchase(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only for Mobile app webview
    // Verify if payment has succeed
    RNMessage.post({ error: 'Payment has failed' });
    dispatch(getPurchase(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (purchase) {
      const expiration = purchase.expiresAt;

      if (!expiration) return;

      const currentTime = moment();
      const expireTime = moment(expiration);
      const remainingTime = expireTime.diff(currentTime, 'seconds', true);

      if (remainingTime <= 0) {
        setExpired(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase]);

  if (!purchase.loaded) return <Loading />;

  return (
    <div className="l-complete">
      <Header isFixed>
        <BrandLogo />
      </Header>

      <CompleteHeader
        title={t('payment:payment_status_canceled')}
        status="canceled"
        total={purchase.total}
      />
      <PurchaseLayout
        sidebar={
          <Spacing size="XL" vertical>
            <div>
              <PaymentStatusIcon />
              {paymentEngine === 'evertec' && <EvertecPaymentDetails />}
            </div>
          </Spacing>
        }
      >
        <Spacing vertical size="XL">
          <Text>{t('payment:text.purchase_canceled')}</Text>
          {!expired && paymentEngine === 'evertec' && (
            <div>
              <Button
                onClick={retryPayment}
                text={t('payment:button.try_the_pay_again')}
                variant="primary"
              />
            </div>
          )}
          <Text>
            {t('payment:text.more_information_in')}
            {email && (
              <>
                <span>{` ${t('purchase:text.at')} `}</span>
                <Link href={`mailto:${email}`} text={email} size="M" type="accent" />
              </>
            )}
            {phoneItems && phoneItems.length && (
              <span>
                <span>{email && ` ${t('purchase:text.or')} `}</span>
                {phones.length > 1
                  ? ` ${t('purchase:text.in_the_lines')} `
                  : ` ${t('purchase:text.on_the_line')} `}
                {phoneItems}
              </span>
            )}
          </Text>
          <Text weight="bold">{t('payment:text.happy_to_help_you')}</Text>
        </Spacing>
      </PurchaseLayout>
    </div>
  );
};

export default CanceledPayment;
