import React from 'react';
import { trackEvent } from 'user-analytics';
import whatsappLogo from 'images/whatsapp/whatsapp-inline-white.svg';
import PropTypes from 'prop-types';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

/**
 * WhatsappButton component
 *
 * This component renders a WhatsApp button with a logo and a link to the WhatsApp contact.
 *
 * @param {Object} props - The component props
 * @param {string} [props.token] - The token of the purchase (required if no messageText is provided)
 * @param {string} [props.messageText] - Custom message text to send via WhatsApp (defaults to "Ayuda con el pago de mi compra {token}")
 * @returns {React.ReactElement} The WhatsApp button component
 */
function WhatsappButton({ token, messageText }) {
  const { provider } = useWhitelabelCopies();
  const { WHATSAPP_FAILED_PAYMENT_BUTTON } = useWhitelabelFeatures();
  const { whatsapp } = provider;
  if (!WHATSAPP_FAILED_PAYMENT_BUTTON || !whatsapp?.length || !whatsapp?.[0]?.value) return null;
  const { countryCode = '52', value } = whatsapp[0];

  // If no messageText is provided, we need a token for the default message
  if (!messageText && !token) {
    console.warn('WhatsappButton: Either messageText or token must be provided');
    return null;
  }

  const defaultMessage = token
    ? `Ayuda con el pago de mi compra ${token}`
    : 'Ayuda con el pago de mi compra';
  const message = messageText || defaultMessage;
  const encodedMessage = encodeURIComponent(message);

  const whatsappUrl = `https://api.whatsapp.com/send/?app_absent=0&phone=${countryCode}${value}&text=${encodedMessage}`;

  return (
    <a
      className="bg-whatsapp-primary h-[50px] md:min-w-[174px] w-full md:max-w-fit rounded p-2 transition-all duration-200 ease-in-out border border-whatsapp-accent hover:bg-whatsapp-accent"
      href={whatsappUrl}
      target="_blank"
      tabIndex="0"
      onClick={() =>
        trackEvent('Failed Payment WhatsApp Support Clicked', {
          Token: token || 'not_provided',
        })
      }
    >
      <img className="w-full h-full object-contain" src={whatsappLogo} alt="Whatsapp" />
    </a>
  );
}

WhatsappButton.propTypes = {
  token: PropTypes.string,
  messageText: PropTypes.string,
};

WhatsappButton.defaultProps = {
  token: '',
  messageText: '',
};

export default WhatsappButton;
