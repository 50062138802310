import { connect } from 'react-redux';
import { toggleWantsServiceLevels } from '../../../actions/purchase';
import ServiceLevels from './ServiceLevels';

const mapStateToProps = ({ purchase }) => {
  const { selectedServiceLevel, token, config, updatingServiceLevel } = purchase.toJS();

  const { serviceLevels } = config;

  return {
    isUpdating: updatingServiceLevel,
    selectedServiceLevel,
    serviceLevels,
    token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  /**
   * Updates the service level for a purchase
   *
   * @param {string} token - Authentication token
   * @param {Object} serviceLevelId - Service level ID object to update
   * @returns {Function} Redux dispatch function to update purchase fields
   */
  updateServiceLevel: (token, serviceLevelId) =>
    token
      ? dispatch(toggleWantsServiceLevels(token, serviceLevelId))
      : console.warn('No token provided for service level update'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLevels);
