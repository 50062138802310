import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, LinkButton } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import slBasic from 'images/brands-unique/reservamos/sl-basic.svg';
import slMax from 'images/brands-unique/reservamos/sl-max.svg';

/**
 * BenefitsInvoicing component
 *
 * This component displays the benefits of invoicing based on the service level.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.url - The URL for the CTA button
 * @param {Object} props.serviceLevel - The service level object
 * @returns {JSX.Element} The rendered component
 */
const BenefitsInvoicing = ({ url, serviceLevel }) => {
  const { t } = useTranslation(['invoicing', 'purchase']);

  return (
    <Spacing vertical>
      <p className="text-primary-regular text-md font-bold">{t('benefits.title')}</p>

      <p className="text-gray-medium text-sm">{t('benefits.description')}</p>
      <Spacing fullWidth flexGrow>
        {serviceLevel && (
          <div className="flex flex-row items-center justify-center pr-2.5 border-r border-gray-600">
            <img
              src={serviceLevel.name === 'basic' ? slBasic : slMax}
              className="w-6 h-6"
              alt={serviceLevel.name === 'basic' ? 'Basic service level' : 'Max service level'}
            />

            <p className="text-gray-500 text-sm">
              {t(`purchase:service-levels.${serviceLevel?.name}`, { context: 'title' })}
            </p>
          </div>
        )}
        <div className="flex flex-row items-center justify-center pr-2.5 border-r-0">
          <p className="text-gray-500 text-sm">{t('purchase:label.service_charge')}</p>
        </div>
      </Spacing>

      <LinkButton
        newTab
        fullWidth
        variant="accent"
        text={t('benefits.cta')}
        buttonType="submit"
        href={url}
      />
    </Spacing>
  );
};

BenefitsInvoicing.propTypes = {
  url: PropTypes.string.isRequired,
  serviceLevel: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default BenefitsInvoicing;
