import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix, getCurrencyPrefix } from 'utils/currency';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PricingRow from '../../ui/atoms/PricingRow';

/**
 * PurchasePricingExtras component.
 * Renders a breakdown of additional costs like carbon credits, service fees, insurance and installment fees.
 * @param {object} props - carbon, total, markup, insurance, installmentsFee, serviceLevel
 * @param {number} props.carbon - Price of carbon offset credits
 * @param {number} props.total - Total price of all extra charges
 * @param {number} props.markup - Service fee markup amount
 * @param {number} props.insurance - Travel insurance cost
 * @param {number} props.installmentsFee - Fee for installment payment plans
 * @param {object} props.serviceLevel - Selected service level details
 * @returns {JSX.Element} Component that displays pricing extras breakdown
 */
const PurchasePricingExtras = ({
  carbon,
  total,
  markup,
  insurance,
  installmentsFee,
  serviceLevel,
}) => {
  const { t } = useTranslation('purchase');
  const { brand } = useWhitelabelEnvs();

  if (total <= 0) return null;

  return (
    <div className="section-content">
      {markup > 0 && (
        <PricingRow
          label={t('label.service_charge')}
          tooltipContent={t('text.working_for_a_better_shopping_experience')}
          price={markup}
          currency={getCurrencySuffix()}
          priceAlignment="flex-end"
        />
      )}

      {serviceLevel.total > 0 && (
        <PricingRow
          label={t(`service-levels.${serviceLevel.name}`, {
            context: 'title',
          })}
          price={serviceLevel.total}
          currency={getCurrencySuffix()}
          tooltipContent={t(`tooltip.service_level`, {
            context: serviceLevel?.name,
          })}
          priceAlignment="flex-end"
        />
      )}

      {insurance > 0 && (
        <PricingRow
          label={t('label.additional_coverage', { context: brand })}
          price={insurance}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
          priceAlignment="flex-end"
        />
      )}

      {carbon > 0 && (
        <PricingRow
          label={t('label.carbon_offset', { context: brand })}
          price={carbon}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
          priceAlignment="flex-end"
        />
      )}

      {installmentsFee > 0 && (
        <PricingRow
          label={t('label.deferred_payment_fee')}
          price={installmentsFee}
          currency={getCurrencySuffix()}
          sign={getCurrencyPrefix()}
          priceAlignment="flex-end"
        />
      )}

      <PricingRow
        label={t('label.additional')}
        price={total}
        currency={getCurrencySuffix()}
        sign={getCurrencyPrefix()}
        isMain
        priceAlignment="flex-end"
      />
    </div>
  );
};

PurchasePricingExtras.propTypes = {
  carbon: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  installmentsFee: PropTypes.number.isRequired,
  insurance: PropTypes.number.isRequired,
  markup: PropTypes.number.isRequired,
  serviceLevel: PropTypes.shape({
    name: PropTypes.string,
    total: PropTypes.number,
  }),
};

PurchasePricingExtras.defaultProps = {
  serviceLevel: {
    name: 'basic',
    total: 0,
  },
};

export default PurchasePricingExtras;
