import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, LinkButton } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import WhatsappButton from 'components/WhatsappButton';

/**
 * ProviderInvoicing component
 *
 * This component displays a list of provider invoicing URLs with corresponding
 * call-to-action buttons.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array<string>} props.urls - The array of invoicing URLs
 * @param {string} props.token - The token of the purchase
 * @returns {JSX.Element} The rendered component
 */
const ProviderInvoicing = ({ urls, token }) => {
  const { t } = useTranslation('invoicing');

  return (
    <Spacing vertical fullWidth>
      {urls.length > 0 ? (
        urls.map((url, i) => {
          return (
            <div className="flex flex-col gap-2" key={url}>
              <p className="text-sm font-semibold text-gray-500">
                {t('provider.invoice', { count: i + 1 })}
              </p>

              <LinkButton
                newTab
                fullWidth
                variant="accent"
                text={t('provider.cta')}
                buttonType="submit"
                href={url}
              />
            </div>
          );
        })
      ) : (
        <>
          <p className="text-sm font-bold text-gray-500">{t('provider.no_urls')}</p>
          <WhatsappButton messageText={t('provider.contact_message', { token })} />
        </>
      )}
    </Spacing>
  );
};

ProviderInvoicing.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string),
  token: PropTypes.string,
};

export default ProviderInvoicing;
