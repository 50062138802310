import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Gap, Spacing, Text, SpinLoader } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import HourTag from 'ui/atoms/HourTag';
import { processNextDepartures } from 'utils/purchase/timer';
import usePurchase from 'hooks/store/usePurchase';
import { pollNextDepartures } from '@/actions/purchase';
import PropTypes from 'prop-types';

/**
 * NextHoursDepartures component.
 * Displays a list of upcoming departure times.
 * @component
 * @param {string} tripId - ID of the trip to show departures for
 * @returns {JSX.Element} A component displaying upcoming departure times with their respective icons
 */
function NextHoursDepartures({ tripId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token, departures } = usePurchase();
  const [hasError, setHasError] = useState(false);

  const { SHOW_NEXT_DEPARTURES } = useWhitelabelFeatures();

  useEffect(() => {
    if (departures?.status === 'pending') {
      dispatch(pollNextDepartures(token)).catch(() => {
        setHasError(true);
      });
    }
  }, [departures?.status, token, dispatch]);

  const allNextDepartures = processNextDepartures(departures?.trips);

  // Use filtered array for round trip cases, only showing next departures corresponding to departure or return
  // TODO: determine how to show next departures on 'completed' page for future trip connections our round trip cases
  const departuresToShow = tripId
    ? allNextDepartures.filter((slot) => slot.id === tripId)
    : allNextDepartures;

  // Don't show the component if:
  // - The feature flag is disabled
  // - There are no departures to show AND there's no error
  // - A tripId exists but has no matches AND there's no error
  if (
    !SHOW_NEXT_DEPARTURES ||
    (!hasError && !departuresToShow.length) ||
    (!hasError && tripId && !departuresToShow.length)
  ) {
    return null;
  }

  return (
    <Spacing vertical size="S">
      <Spacing justifyContent="space-between" alignItems="center">
        <Text weight="bold" size="S">
          {`${t('general:next_departures')} ${' '}`}
        </Text>
      </Spacing>
      {hasError && (
        <Text color="error">{t('notifications:warning.error_getting_next_departures')}</Text>
      )}
      {!hasError && departures?.isLoading && (
        <Spacing justifyContent="center" alignItems="center">
          <SpinLoader isLoading={departures.isLoading} color="primary" size="M" />
        </Spacing>
      )}
      {!hasError && !departures?.isLoading && (
        <Box
          borderRadius="M"
          border="all"
          borderColor="grayBorder"
          paddingHorizontal="S"
          paddingVertical="S"
        >
          <Gap rowGap="S" columnGap="S">
            {departuresToShow.map((slot) => (
              <HourTag key={slot.id} icon={slot.period} label={slot.time} />
            ))}
          </Gap>
        </Box>
      )}
    </Spacing>
  );
}

NextHoursDepartures.propTypes = {
  tripId: PropTypes.string,
};

NextHoursDepartures.defaultProps = {
  tripId: null,
};

export default NextHoursDepartures;
