import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text, ButtonCard, Spacing, MessageBox } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import paypal from 'images/payment/paypal.svg';
import coppelPay from 'images/payment/coppelpay.png';
import oxxo from 'images/payment/oxxo.svg';
import tarjetaNoAmex from 'images/payment/tarjeta-2.svg';
import tarjeta from 'images/payment/tarjeta.svg';
import tarjetaNoMC from 'images/payment/tarjeta-visa-amex.svg';
import tarjetaPt from 'images/payment/tarjeta-pt.svg';
import pse from 'images/payment/pse.png';
import kueskipay from 'images/payment/kueskipay.svg';
import aplazo from 'images/payment/aplazo-blue.svg';
import mercadopago from 'images/payment/mercadopago.svg';
import isotypeSeven from 'images/payment/seven.png';
import isotypeAurrera from 'images/payment/isotype-aurrera.png';
import isotypeCirclek from 'images/payment/isotype-circlek.png';
import isotypeSoriana from 'images/payment/isotype-soriana.png';
import isotypeWallmart from 'images/payment/isotype-wallmart.png';
import pixLogo from 'images/payment/pix.png';
import efectyLogo from 'images/payment/efecty.png';
import nequiLogo from 'images/payment/nequi.svg';
import viajamas from 'images/brands-unique/etn/viajamas.png';
import moveObjToFirst from 'utils/sortObjectArray';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useGrowthBookFeaturesValue from 'components/GrowthBookProvider/useGrowthBookFeaturesValues';
import BadgeNew from './BadgeNew';
import './PaymentSelector.scss';
import yape from '../../images/payment/yuno/yape_plin.png';

// Constants moved outside component to prevent recreation on each render
// These are the logos for different store payment options shown in the paycash method
const PAY_CASH_IMAGES = [
  isotypeAurrera,
  isotypeSeven,
  isotypeCirclek,
  isotypeSoriana,
  isotypeWallmart,
];

// Mapping from Yuno API payment categories to our internal payment types
// This mapping ensures consistent payment type usage across the application
const CATEGORY_TYPE_MAP = {
  ticket: 'store', // Physical store payments like OXXO
  payment_link: 'transfer', // Bank transfers
  buy_now_pay_later: 'bnpl', // Buy now, pay later options
};

/**
 * PaymentSelector component allows users to select a payment method.
 *
 * This component handles multiple payment sources:
 * 1. Legacy availablePayments (string array)
 * 2. New paymentMethods structure (objects with type/provider)
 * 3. Yuno payment gateway methods
 *
 * It normalizes these different structures into a unified format
 * and renders them as selectable payment options.
 *
 * @param {Object} props - Component props.
 * @param {string[]} props.availablePayments - List of available payment methods (legacy format).
 * @param {Object[]} props.paymentMethods - List of payment method objects (new format).
 * @param {string} props.selectedOption - Currently selected payment method (legacy format).
 * @param {Function} props.onPaymentTypeChange - Callback function when payment type changes.
 * @returns {React.Component} A component that renders the payment options.
 */
const PaymentSelector = ({
  availablePayments,
  paymentMethods = [],
  selectedOption,
  onPaymentTypeChange,
}) => {
  // Get white-label configuration and feature flags
  const { supportAmexCard, defaultPaymentOption, brand } = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();

  // Get selected payment method from Redux store
  const selectedPaymentMethod = useSelector((state) => state.purchase.get('selectedPaymentMethod'));

  // Get Yuno payment gateway configuration from Redux store
  const yuno = useSelector((state) => state.purchase.get('yuno'));

  // Internationalization hook
  const { t } = useTranslation('payment');

  // Feature flag for showing Yape logo
  const { showYapeLogo } = useGrowthBookFeaturesValue('show_yape_logo');

  /**
   * Determines the appropriate card logo based on available card brands and Amex support.
   * Different logos are shown depending on which card types are supported.
   *
   * @returns {string} The path to the card logo image.
   */
  const getCardLogo = useCallback(() => {
    const hasCybersourceBrands = features.CYBERSOURCE_AVAILABLE_CARD_BRANDS?.length > 0;
    const supportsMastercard = features.CYBERSOURCE_AVAILABLE_CARD_BRANDS?.includes('mastercard');

    if (hasCybersourceBrands && !supportsMastercard) {
      return tarjetaNoMC; // Show logo without Mastercard
    }

    return supportAmexCard ? tarjeta : tarjetaNoAmex; // Show either with or without Amex
  }, [features.CYBERSOURCE_AVAILABLE_CARD_BRANDS, supportAmexCard]);

  /**
   * Payment methods configuration - memoized to prevent recreation on each render
   *
   * Structure:
   * - First level keys: payment types (e.g., 'transfer', 'credit_card')
   * - Second level keys: payment providers (e.g., 'pse', 'oxxo')
   *
   * Each payment method contains:
   * - img: Logo image path
   * - label: Translated text to display
   * - isNew: Whether to show the "new" badge
   * - Additional properties like 'images' for multiple logos
   */
  const methods = useMemo(
    () => ({
      // Bank transfers (PSE, PIX, Nequi)
      transfer: {
        pse: {
          img: pse,
          label: t('button.payment_method', { context: 'transfer' }),
          isNew: false,
        },
        pix: {
          img: pixLogo,
          label: t('button.payment_method', { context: 'pix' }),
          isNew: false,
        },
        evertec: {
          img: pse,
          label: t('button.payment_method', { context: 'transfer' }),
          isNew: false,
        },
        nequi: {
          img: nequiLogo,
          label: t('button.payment_method', { context: 'nequi' }),
          isNew: true,
        },
      },
      // Digital wallets
      wallet: {
        mercadopago: {
          img: mercadopago,
          label: t('button.payment_method', { context: 'wallet' }),
          isNew: true,
        },
      },
      // Physical store payments (OXXO, PayCash)
      store: {
        isStoreIcon: true,
        label: t('button.payment_method', { context: 'store' }),
        isNew: false,
        oxxo: {
          img: oxxo,
          label: t('button.payment_method', { context: 'oxxo' }),
          isNew: false,
        },
        paycash: {
          label: t('button.payment_method', { context: 'store' }),
          isNew: true,
          images: PAY_CASH_IMAGES,
          inlineImages: true,
        },
      },
      // Credit and debit cards
      credit_card: {
        img: getCardLogo(),
        label: t('button.payment_method', { context: 'credit' }),
        isNew: false,
        evertec: {
          img: supportAmexCard ? tarjeta : tarjetaNoAmex,
          label: t('button.payment_method', { context: 'credit' }),
          isNew: false,
        },
        cielo: {
          img: tarjetaPt,
          label: t('button.payment_method', { context: 'credit' }),
          isNew: false,
        },
      },
      // Third-party payment methods
      third_party: {
        coppel_pay: {
          img: coppelPay,
          label: t('button.payment_method', { context: 'coppelpay' }),
          isNew: true,
        },
      },
      // Buy now, pay later options
      bnpl: {
        kueski: {
          isNew: true,
          img: kueskipay,
          label: t('button.payment_method', { context: 'loan' }),
        },
        aplazo: {
          isNew: true,
          img: aplazo,
          label: t('button.payment_method', { context: 'biweekly' }),
        },
      },
      // The following are legacy payment types that don't follow the type/provider pattern
      // They are kept for backward compatibility
      paypal: {
        img: paypal,
        label: t('button.payment_method', { context: 'paypal' }),
        isNew: false,
      },
      paycash: {
        label: t('button.payment_method', { context: 'store' }),
        isNew: true,
        images: PAY_CASH_IMAGES,
        inlineImages: true,
      },
      kueskipay: {
        img: kueskipay,
        label: t('button.payment_method', { context: 'loan' }),
        isNew: true,
      },
      reservamos_pay: {
        img: viajamas,
        label: t('button.payment_method', { context: 'reservamos_pay' }),
        isNew: false,
      },
      efecty: {
        img: efectyLogo,
        label: t('button.payment_method', { context: 'efecty' }),
        isNew: false,
      },
    }),
    [t, supportAmexCard, getCardLogo],
  );

  /**
   * Process and combine all payment methods from different sources
   * into a unified list for rendering.
   */
  const payments = useMemo(() => {
    // Parse legacy availablePayments format (string array)
    // Filter out any payment methods that already exist in the new format
    const parsedAvailablePayments = availablePayments
      .filter(
        (payment) =>
          !paymentMethods.some((method) => method.type === payment || method.provider === payment),
      )
      .map((payment) => ({ type: payment }));

    // Parse Yuno payment methods if available
    // Yuno has its own format that needs to be converted to our internal format
    const parsedYunoPayments =
      yuno?.availableMethods?.map((method) => {
        const category = method?.category?.toLowerCase() || '';
        // Map Yuno's payment categories to our internal payment types
        const type = CATEGORY_TYPE_MAP[category] || category;
        // Special case: show Yape logo for PagoEfectivo when feature flag is enabled
        const img = showYapeLogo && method.name === 'PagoEfectivo' ? yape : method.icon;

        return {
          label: t(
            // Special label for buy now, pay later options
            category === 'buy_now_pay_later' ? 'button.payment_bnpl' : 'button.payment_method',
            { context: 'yuno_default' },
          ),
          provider: 'yuno',
          engine: method.type.toLowerCase(),
          type,
          img,
          enabled: method.checkout.conditions.enabled,
          isNew: method.isNew || false,
        };
      }) || [];

    // Combine all payment methods from different sources
    let allPayments = [...parsedAvailablePayments, ...paymentMethods, ...parsedYunoPayments];

    // Change app_notify type to transfer
    allPayments = allPayments.map((payment) => {
      if (payment.type === 'app_notify') {
        return {
          ...payment,
          type: 'transfer',
        };
      }
      return payment;
    });

    // Move default payment option to the first position if feature is enabled
    if (features.DEFAULT_PAYMENT_OPTION_FIRST) {
      allPayments = moveObjToFirst(allPayments, 'type', defaultPaymentOption);
    }

    return allPayments;
  }, [
    availablePayments,
    paymentMethods,
    yuno?.availableMethods,
    showYapeLogo,
    t,
    features.DEFAULT_PAYMENT_OPTION_FIRST,
    defaultPaymentOption,
  ]);

  // Sync selectedOption with selectedPaymentMethod when needed
  useEffect(() => {
    // Only run if selectedOption exists but selectedPaymentMethod doesn't
    if (selectedOption && !selectedPaymentMethod?.provider) {
      // Try to find a payment with matching type but no engine
      let paymentToSelect = payments.find(
        (payment) => payment.type === selectedOption && !payment.engine,
      );

      // If not found, get the first payment with matching type
      if (!paymentToSelect) {
        paymentToSelect = payments.find((payment) => payment.type === selectedOption);
        // If a suitable payment was found, select it
        if (paymentToSelect) {
          const isNewStructure = Boolean(paymentToSelect.type && paymentToSelect.provider);
          onPaymentTypeChange(paymentToSelect.type, isNewStructure ? paymentToSelect : undefined);
        }
      }
    }
  }, [selectedOption, selectedPaymentMethod, payments, onPaymentTypeChange]);

  // Check if the selected payment method is still available
  useEffect(() => {
    // Only execute this logic if there is a selected payment method
    if (selectedPaymentMethod && (selectedPaymentMethod.type || selectedPaymentMethod.provider)) {
      // Check if the selected method is still in the list of available payments
      const isMethodAvailable = payments.some((payment) => {
        const sameType = payment.type === selectedPaymentMethod.type;
        const sameProvider =
          payment.provider === selectedPaymentMethod.provider ||
          (!payment.provider && !selectedPaymentMethod.provider);
        return sameType && sameProvider;
      });

      // If the selected method is no longer available, look for the default or first one
      if (!isMethodAvailable && payments.length > 0) {
        // First, look for the default payment method configured in defaultPaymentOption
        const defaultConfiguredPayment = payments.find((p) => p.type === defaultPaymentOption);

        // Use the default if it exists, or the first available if not
        const defaultPayment = defaultConfiguredPayment || payments[0];

        // Select the new payment method
        const isNewStructure = Boolean(defaultPayment.type && defaultPayment.provider);
        onPaymentTypeChange(defaultPayment.type, isNewStructure ? defaultPayment : undefined);
      }
    }
  }, [payments, selectedPaymentMethod, defaultPaymentOption, onPaymentTypeChange]);

  /**
   * Returns a function that handles the selection of a payment method.
   *
   * @param {Object} payment - The payment method object.
   * @param {Function} closeModal - Optional function to close the modal after selection.
   * @returns {Function} Handler function for the payment selection.
   */
  const handleSelectPayment = (payment, closeModal) => () => {
    // Check if the payment uses the new object structure with type and provider
    const isNewStructure = Boolean(payment.type && payment.provider);
    // Call the parent callback with appropriate parameters based on structure
    onPaymentTypeChange(payment.type, isNewStructure ? payment : undefined);
    // Close the modal if provided (used when rendered inside a modal)
    if (closeModal) closeModal();
  };

  /**
   * Renders a single payment method card.
   *
   * @param {Object} payment - The payment method object.
   * @param {Function} closeModal - Optional function to close the modal after selection.
   * @returns {React.Element|null} The payment card element or null if it shouldn't be displayed.
   */
  const renderPaymentCard = (payment, closeModal) => {
    const { type, provider } = payment;
    // Check if this is a Yuno payment method
    const isYuno = Boolean(payment.provider === 'yuno');

    // Get the method type configuration
    // For Yuno methods, use the provided image and label
    // For other methods, look up in the methods configuration
    const methodType = isYuno ? { img: payment.img, label: payment.label } : methods[type];

    // Skip rendering invalid payment methods
    // - If the type is not in our methods configuration (except for PIX which is handled specially)
    // - If it's a Yuno method that's disabled or uses Yuno's engine directly
    if (
      (!methodType && type !== 'pix') ||
      ((payment.engine === 'yuno' || !payment.enabled) && isYuno)
    ) {
      return null;
    }

    // Get the appropriate method configuration
    let method;
    if (!methodType && type === 'pix') {
      // Special case for PIX payments
      method = methods.transfer.pix;
    } else {
      // Get provider-specific config or fallback to general type config
      method = methodType[provider] || methodType;

      // Special case for old-structure transfer payments - use PSE config
      const isOldStructure = !provider;
      if (isOldStructure && type === 'transfer') {
        method = methodType.pse;
      }
    }

    // Determine if the method should show the "New" badge
    const isNew = payment.isNew || method.isNew || false;

    // Determine if this payment method is currently selected
    // Complex logic due to supporting both old and new payment method structures
    const isActive =
      (selectedPaymentMethod?.provider === provider && selectedPaymentMethod?.type === type) ||
      (!provider && type === selectedOption);

    return (
      <div key={`${type}-${provider || ''}`} className="payment-selector-badge">
        {/* Display "New" badge if the payment method is marked as new */}
        {isNew && (
          <div className="payment-selector-badge-wrapper">
            <BadgeNew />
          </div>
        )}
        <ButtonCard
          imgSrc={method.img}
          images={method.images}
          isStoreIcon={method.isStoreIcon}
          inlineImages={method.inlineImages}
          onClick={handleSelectPayment(payment, closeModal)}
          // Different active state detection based on payment method structure
          isActive={isActive}
        >
          <Text color="grayMedium" size="XS" whiteSpace="nowrap">
            {method.label}
          </Text>
        </ButtonCard>
      </div>
    );
  };

  /**
   * Renders all payment method cards in a grid.
   *
   * @param {Function} closeModal - Optional function to close the modal after selection.
   * @returns {React.Element} Container with all payment method cards.
   */
  const renderPaymentCards = (closeModal) => (
    <div className="payment-selector">
      {payments.map((payment) => renderPaymentCard(payment, closeModal))}
    </div>
  );

  // Don't render the component if there's only one or zero payment methods
  // No selection is needed in that case
  if (payments.length <= 1) return null;

  return (
    <Spacing vertical size="S" responsiveSize="XS">
      {/* Component title */}
      <Text weight="bold" size="XL">
        {t('select_payment_method')}
      </Text>

      {/* Optional payment disclaimer based on feature flag */}
      {features.SHOW_PAYMENT_DISCLAIMER && (
        <MessageBox borderColor="primary">
          <Text>
            <Trans t={t} i18nKey="text.payment_disclaimer" tOptions={{ context: brand }}>
              text<b>text</b>text
            </Trans>
          </Text>
        </MessageBox>
      )}

      {/* Render the payment method selection grid */}
      {renderPaymentCards()}
    </Spacing>
  );
};

PaymentSelector.propTypes = {
  selectedOption: PropTypes.string,
  availablePayments: PropTypes.array,
  onPaymentTypeChange: PropTypes.func,
  paymentMethods: PropTypes.array,
};

PaymentSelector.defaultProps = {
  selectedOption: '',
  availablePayments: [],
  onPaymentTypeChange: () => {},
};

export default PaymentSelector;
