import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/Helpers';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrencySuffix } from 'utils/currency';

const propTypes = {
  paymentPlans: PropTypes.objectOf(PropTypes.number),
  installmentsMinAmount: PropTypes.number.isRequired,
};

const InstallmentsTooltip = ({ paymentPlans, installmentsMinAmount }) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('general');

  const { availableBanks } = env.installments;
  if (!features.INSTALLMENTS_ENABLED || !paymentPlans) return null;

  const maxInstallments = Math.max(...Object.keys(paymentPlans));
  const displayPrice = Math.min(...Object.values(paymentPlans));

  const months = Object.keys(paymentPlans);
  const last = months.length - 1;
  const displayMonths = `${months.slice(0, last).join(', ')} y ${months[last]}`;
  const paymentPlansAvailable = maxInstallments > 0;
  const tooltipCopyMaxInstallments = paymentPlansAvailable ? maxInstallments : 12;

  return (
    <Tooltip className="installments-tooltip">
      <span className="installments-tooltip-copy">
        <i className="installments-tooltip-icon" />{' '}
        {t('installments.fixed', { maxInstallments: tooltipCopyMaxInstallments })}
      </span>

      <div className="tooltip">
        <div className="tooltip-header">
          <p className="tooltip-title">
            {t('installments.title', {
              context: paymentPlansAvailable ? 'available' : 'unavailable',
            })}
          </p>

          <p className="tooltip-paragraph">
            {t('installments.content', {
              context: paymentPlansAvailable ? 'available' : 'unavailable',
              minAmount: paymentPlansAvailable
                ? formatCurrency(displayPrice)
                : formatCurrency(installmentsMinAmount),
              months: displayMonths,
              currency: getCurrencySuffix(),
            })}
          </p>
        </div>

        <div className="tooltip-content">
          <div className="grid grid-cols-6 gap-4">
            {availableBanks.map((bankName) => (
              <div key={bankName} className="flex items-center justify-center bank-icon">
                <i className={`bank-${bankName}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

InstallmentsTooltip.propTypes = propTypes;

InstallmentsTooltip.defaultProps = {
  paymentPlans: [],
};

export default InstallmentsTooltip;
