import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { Spacing } from '@reservamos/elements';
import AplazoIcons from 'ui/templates/AplazoIcons';
import CheckList from '../../ui/atoms/CheckList';
import PaymentImage from '../../ui/atoms/PaymentImage';

const AplazoForm = ({ isOpenTicket }) => {
  const env = useWhitelabelEnvs();
  const { t } = useTranslation('payment');

  const defaultLabels = [
    t('aplazo_instructions.first'),
    t('aplazo_instructions.second'),
    t('aplazo_instructions.third'),
  ];

  const gfa = [...defaultLabels];
  if (!isOpenTicket) gfa.push(t('purchase:text.secure_seat_until_payment_aplazo'));
  const rollbits = [...defaultLabels];
  if (!isOpenTicket) rollbits.push(t('purchase:text.secure_seat_until_payment_aplazo'));

  const labelsType = {
    gfa,
    rollbits,
    default: defaultLabels,
  };

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <PaymentImage type="aplazo-logo-blue" />

        <AplazoIcons />

        <CheckList
          title={t('label.pay_with_aplazo')}
          labels={labelsType[env.brand] ?? labelsType.default}
        />
      </Spacing>
    </div>
  );
};

AplazoForm.propTypes = {
  isOpenTicket: PropTypes.bool.isRequired,
};

export default AplazoForm;
