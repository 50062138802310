import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import TimeLineItem from './TimeLineItem';
import makeKey from './makeKey';
import 'styles/components/TripItinerary/ItineraryDetails';
import StopCollapse from './TimeLineItem/StopCollapse';

const propTypes = {
  route: PropTypes.object,
  brand: PropTypes.string,
  lineDetails: PropTypes.object,
};

/**
 * Component used as part of trip itinerary.
 * @param {Object} props - Object props
 * @param {object} props.route - Indicates full route data.
 * @param {object[]} props.route.route - Contains a summary depart and end trip data .
 * @param {object} props.route.originDetails - Indicates trip's origin data.
 * @param {object} props.route.destinationDetails - Indicates trip's destination data.
 * @param {object} props.lineDetails - Object containing provider's line detail.
 * @returns {JSX.Element} A React component that renders trip's timeline .
 */
const TimeLine = ({ route: { route, originDetails, destinationDetails }, lineDetails }) => {
  const { brand } = useWhitelabelEnvs();
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Check if any stop has nextDayBadge
  const hasNextDayBadge = route.some(
    (segment) =>
      segment.time && Array.isArray(segment.time) && segment.time.some((t) => t?.nextDayBadge),
  );

  const finalRoute =
    brand !== 'brasilia'
      ? route
      : route
          .map((timeSegment) => {
            if (timeSegment.type === 'stop') {
              return [
                {
                  type: 'end',
                  time: timeSegment.time[0].time,
                  title: timeSegment.title,
                  description: `Llega a: ${timeSegment.title}`,
                },
                {
                  type: 'departure',
                  time: timeSegment.time[1].time,
                  title: timeSegment.title,
                  description: `Sale de: ${timeSegment.title}`,
                },
              ];
            }
            return timeSegment;
          })
          .flat();

  // Separate stop type segments
  const stopSegments = finalRoute?.filter((segment) => segment.type === 'stop');
  const hasCollapsibleStops = stopSegments?.length > 3;

  let index = 1;
  const timeLineItems = finalRoute?.map((timeSegment) => {
    const key = makeKey(timeSegment);
    if (timeSegment.type === 'departure' && brand === 'brasilia') {
      timeSegment.index = index;
      index += 1;
    }

    /**
     * Brasilia is a particular case, we avoid to remove time segment on it
     * because they show different "Ruta" where end of first one could be same as start of second
     * and we need to show both times.
     */
    if (brand !== 'brasilia') {
      if (timeSegment.type === 'stop') {
        const isSameTime = timeSegment.time[0]?.time === timeSegment.time[1]?.time;
        if (isSameTime) {
          delete timeSegment.time[0];
        }
      }
    }

    // Logic to show/hide stops
    if (timeSegment.type === 'stop') {
      const stopIndex = stopSegments.findIndex((stop) => stop === timeSegment);

      // If there are more than 3 stops and it's collapsed, don't show any
      if (hasCollapsibleStops && isCollapsed && stopIndex !== 0) {
        return null;
      }

      // Only show StopCollapse at the beginning
      if (hasCollapsibleStops && isCollapsed && stopIndex === 0) {
        return (
          <>
            <StopCollapse
              key={`collapse-${key}`}
              isCollapsed={isCollapsed}
              onToggle={() => setIsCollapsed(!isCollapsed)}
              remainingStops={stopSegments.length}
            />
          </>
        );
      }

      // shows StopCollapse after first stop
      return (
        <>
          {stopIndex === 0 && hasCollapsibleStops && (
            <StopCollapse
              key={`collapse-${key}`}
              isCollapsed={isCollapsed}
              onToggle={() => setIsCollapsed(!isCollapsed)}
              remainingStops={stopSegments.length}
            />
          )}
          <TimeLineItem
            key={key}
            {...timeSegment}
            componentkey={key}
            brand={brand}
            originDetails={originDetails}
            destinationDetails={destinationDetails}
            lineDetails={lineDetails}
            hasNextDayBadge={hasNextDayBadge}
          />
        </>
      );
    }

    return (
      <TimeLineItem
        key={key}
        {...timeSegment}
        componentkey={key}
        brand={brand}
        originDetails={originDetails}
        destinationDetails={destinationDetails}
        lineDetails={lineDetails}
        hasNextDayBadge={hasNextDayBadge}
      />
    );
  });

  return <div>{timeLineItems}</div>;
};

TimeLine.propTypes = propTypes;

export default TimeLine;
