// @ts-check

/**
 * @typedef {import('./strategies/BnplStrategy').BnplStrategy} BnplStrategy
 * @typedef {import('./strategies/BnplStrategy').BnplInfo} BnplInfo
 * @typedef {import('./strategies/BnplStrategy').EnginePayload} EnginePayload
 */

/**
 * BNPL payment method.
 * @function Bnpl
 */
const Bnpl = () => {
  /**
   * @type {BnplStrategy | undefined}
   */
  let strategy;

  /**
   * Sets the BNPL strategy.
   * @param {BnplStrategy} newStrategy - The new BNPL strategy.
   */
  const setStrategy = (newStrategy) => {
    strategy = newStrategy;
  };

  /**
   * Creates the payload for the BNPL payment.
   * @param {BnplInfo} bnplInfo - BNPL information.
   * @param {EnginePayload} enginePayload - Additional payload specific to the engine.
   * @returns {Promise<Object>} The created payload.
   */
  const createPayload = async (bnplInfo, enginePayload = {}) => {
    if (!strategy) {
      throw new Error('BNPL strategy not set');
    }

    return strategy.createPayload(bnplInfo, enginePayload);
  };

  return {
    setStrategy,
    createPayload,
  };
};

const bnpl = Bnpl();

export default bnpl;
