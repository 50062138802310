import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { When } from 'react-if';
import moment from 'moment';
import usePurchase from 'hooks/store/usePurchase';
import { useSelector } from 'react-redux';
import 'styles/components/TripItinerary/TripItinerary';
import { useTranslation } from 'react-i18next';
import { ProviderLogo, Text, Spacing, Box } from '@reservamos/elements';
import ResultItinerary from 'components/search/ResultItinerary';
import Amenity from 'ui/atoms/Amenity';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelTheme from 'hooks/whitelabel/useWhitelabelTheme';
import SeatDetails from './SeatDetails';
import DownloadTicketStep from './ItineraryDetails/DownloadTicketStep';
import DurationHeader from '../../ui/atoms/DurationHeader';
import RedeemOpenTicket from '../purchase/RedeemOpenTicket';

const propTypes = {
  trip: PropTypes.object.isRequired,
  title: PropTypes.string,
  tripId: PropTypes.string,
  itinerary: PropTypes.shape({
    transportType: PropTypes.string.isRequired,
    fragments: PropTypes.array.isRequired,
  }).isRequired,
  providerServices: PropTypes.array.isRequired,
  selectedSeats: PropTypes.array,
  isOpenTicket: PropTypes.bool,
  showDownloadTicketsStep: PropTypes.bool,
  isPurchaseAttempt: PropTypes.bool,
  providerDetails: PropTypes.object.isRequired,
  isRoundTrip: PropTypes.bool,
};

/**
 * Renders amenities section
 */
const Amenities = memo(
  ({
    selectedSeats,
    isOpenTicket,
    providerServices,
    isBulkTicket,
    providerDetails,
    serviceTypeLabel,
  }) => {
    const { t } = useTranslation(['search']);

    if (isBulkTicket && !providerServices.length) return null;

    return (
      <Spacing vertical>
        {!isOpenTicket && <SeatDetails selectedSeats={selectedSeats} removePadding />}
        <Text weight="bold">{t('label.amenities_and_services')}</Text>
        {!isBulkTicket && (
          <Spacing size="S" alignItems="center">
            <ProviderLogo name={providerDetails.name} imgSrc={providerDetails.logoUrl} isSmall />
            <Text size="S">{serviceTypeLabel}</Text>
          </Spacing>
        )}
        <div className="flex flex-wrap gap-2">
          {providerServices.map((amenity) => (
            <Amenity key={amenity} type={amenity} iconSize="S" />
          ))}
        </div>
      </Spacing>
    );
  },
);

Amenities.propTypes = {
  selectedSeats: PropTypes.array,
  isOpenTicket: PropTypes.bool,
  providerServices: PropTypes.array.isRequired,
  isBulkTicket: PropTypes.bool,
  providerDetails: PropTypes.object.isRequired,
  serviceTypeLabel: PropTypes.string,
};

/**
 * Component used as the body of the trip detail modal for default/classic style.
 * @param {Object} props - Object props
 * @param {string} props.title - Indicates the body title.
 * @param {object} props.trip - Full selected trip object.
 * @param {string} props.tripId - The id of the selected trip.
 * @param {object} props.itinerary - Indicates origin, destination and stops.
 * @param {object} props.providerDetails - Indicates brand's trip data. Ex: name, image url, trip service type.
 * @param {array} props.providerServices - Indicates trip's amenities. Ex: wifi, bathroom.
 * @param {number} props.selectedSeats - Indicates current selected seats.
 * @param {boolean} props.isOpenTicket - Indicates if depart or return ticket is an open ticket.
 * @param {boolean} props.showDownloadTicketsStep - Indicates whether to see the download steps in in case the rule is fulfilled.
 * @param {boolean} props.isPurchaseAttempt - Indicates if the user is inside purchase flow.
 * @param {Boolean} props.isRoundTrip - Flag indicating if it's a round trip
 * @returns {JSX.Element} A React component that renders a the current trip detail with itinerary, amenities details, seats and openticket section.
 */
const TripItinerary = ({
  trip,
  tripId,
  itinerary,
  providerDetails,
  providerServices,
  selectedSeats,
  isOpenTicket,
  showDownloadTicketsStep,
  title,
  isRoundTrip,
  isPurchaseAttempt = false,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation(['search', 'purchase']);
  const env = useWhitelabelEnvs();
  const { getServiceType, serviceTypes } = useWhitelabelTheme();
  const { line, departure, arrival, duration, tripSlug, service } = trip;
  const departureMoment = moment(departure);
  const arrivalMoment = moment(arrival);

  const { cityName: originCityName } = trip.origin;
  const { cityName: destinationCityName } = trip.destination;
  const shouldDisplayItinerary = features.SHOW_ITINERARY_AT_TRIP_DETAILS;
  const showAmenitiesBeforeItinerary = features.AMENITIES_FIRST_AT_TRIP_DETAILS;

  const { useLineNameAsServiceType = false } = env.theme.serviceTypes || {};
  const serviceProp = serviceTypes ? service : providerDetails.serviceName;
  const serviceTypeLabel =
    useLineNameAsServiceType && line ? line.name : getServiceType(serviceProp).label;
  const { bulkTicket: isBulkTicket } = usePurchase();

  return (
    <div>
      {!isOpenTicket ? (
        <Spacing vertical size="L">
          {title && isRoundTrip && (
            <Text size="L" weight="bold" textAlign="center" color="primary">
              {t(`purchase:label.${title}`)}
            </Text>
          )}
          <DurationHeader
            departureMoment={departureMoment}
            arrivalMoment={arrivalMoment}
            duration={duration}
          />
          {shouldDisplayItinerary && !showAmenitiesBeforeItinerary && (
            <Spacing vertical size="S">
              {!isPurchaseAttempt && <Text weight="bold">{t('tab.itinerary')}</Text>}
              <ResultItinerary tripId={isPurchaseAttempt ? tripSlug : tripId} />
            </Spacing>
          )}
          <hr className="l-divider" />

          <Amenities
            selectedSeats={selectedSeats}
            isOpenTicket={isOpenTicket}
            providerServices={providerServices}
            isBulkTicket={isBulkTicket}
            providerDetails={providerDetails}
            serviceTypeLabel={serviceTypeLabel}
          />
          {shouldDisplayItinerary && showAmenitiesBeforeItinerary && (
            <Box paddingVertical="M" paddingHorizontal="S">
              <Spacing vertical size="S">
                {!isPurchaseAttempt && <Text weight="bold">{t('tab.itinerary')}</Text>}
                <ResultItinerary tripId={isPurchaseAttempt ? tripSlug : tripId} />
              </Spacing>
            </Box>
          )}
        </Spacing>
      ) : (
        <div>
          <RedeemOpenTicket origin={originCityName} destination={destinationCityName}>
            <Text weight="bold">{t('purchase:label.chosen_open_ticket')}</Text>
            <Text size="S">{t('purchase:open_ticket_instructions')}</Text>
            <When condition={showDownloadTicketsStep}>
              {itinerary.fragments.map((trip) => {
                return <DownloadTicketStep ticketUrl={trip.ticketUrl} key={trip.arrival} />;
              })}
            </When>
          </RedeemOpenTicket>
          <Amenities
            selectedSeats={selectedSeats}
            isOpenTicket={isOpenTicket}
            providerServices={providerServices}
            isBulkTicket={isBulkTicket}
            providerDetails={providerDetails}
            serviceTypeLabel={serviceTypeLabel}
          />
        </div>
      )}
    </div>
  );
};

TripItinerary.propTypes = propTypes;

export default memo(TripItinerary);
