import { trackEvent } from 'user-analytics';
import { Map } from 'immutable';
import { recommendedTripsTypes } from 'constants/recommendedTrips';
import { RECOMMENDED_TRIPS_LOADED, RECOMMENDED_TRIP_SELECTED } from 'constants/TrackEvents';
import store from '../store';
import { getProductType } from './Reserbus';
import { departureAfter } from './tripFilters';
import growthBook from '../services/growthBook';

/**
 * Check if the recommended trips feature is enabled.
 * @returns {boolean} - Whether the recommended trips feature is enabled or not.
 */
export const isRecommendedTripsEnabled = () => {
  const { features, env } = store.getState().whitelabelConfig;
  const validResultType =
    features.SCHEDULE_RESULT_TYPE === 'result' || features.SCHEDULE_RESULT_TYPE === 'simpleResult';
  return validResultType && features.RECOMMENDED_TRIPS_ENABLED && env.coreApi?.enabled;
};

/**
 * Get the list of recommended trips of an specific search.
 *
 * @param {Object} options - Function params as object.
 * @param {Object} options.recommendedTrips - The recommended trips object.
 * @param {string} options.providerId - The provider ID.
 * @param {string} options.searchId - The search ID.
 * @param {string} options.departureTripArrival - The arrival date of the trip.
 * @returns {Array} The list of recommended trips.
 */
export const getRecommendedTripsListBySearch = ({
  recommendedTrips,
  providerId,
  searchId,
  departureTripArrival,
}) => {
  const { features } = store.getState().whitelabelConfig;
  const recommendedWayTrips = recommendedTrips?.[searchId];
  if (!recommendedWayTrips) return [];

  let trips = [];
  Object.keys(recommendedWayTrips).forEach((recommendationType) => {
    const typeTrips = recommendedWayTrips[recommendationType];
    if (typeTrips.length) {
      let trip;
      if (providerId) {
        trip = typeTrips.find((typeTrip) => {
          const isSameProvider = typeTrip.providerId === providerId;

          // If the departureTripArrival is set, we need to check if the departure trip is after the arrival date.
          return departureTripArrival
            ? departureAfter(Map(typeTrip), departureTripArrival) && isSameProvider
            : isSameProvider;
        });
      }
      // If the departureTripArrival is set, we need to check if the departure trip is after the arrival date.
      if (departureTripArrival) {
        const tripFiltered = typeTrips.find(
          (typeTrip) => departureAfter(Map(typeTrip), departureTripArrival),
          // eslint-disable-next-line function-paren-newline
        );
        if (tripFiltered) trip = { ...tripFiltered };
      } else {
        trip = { ...typeTrips[0] };
      }

      // For tenants with multiple providers, only show trips from the selected provider.
      if (trip && (!providerId || providerId === trip.providerId)) trips.push(trip);
    }
  });
  const frequentTrips = trips.filter((trip) => trip.recommendationConcept === 'frequent');
  if (frequentTrips.length)
    trips = [
      frequentTrips[0],
      ...trips.filter((trip) => trip.recommendationConcept !== 'frequent'),
    ];

  const limit = getProductType() === 'desktop' ? features.RECOMMENDED_TRIPS_LIMIT : 1;
  return trips.slice(0, limit);
};

/**
 * Create the recommendations objects but with each category with an array of trips parsed.
 *
 * @param {Object} options - Function params as object.
 * @param {Object} options.recommendedTrips - The recommended trips object.
 * @param {Array} options.searchTrips - The search trips array.
 * @returns {Object} The trips grouped by recommendation type.
 */
export const getRecommendationsWithTrips = ({ recommendedTrips, searchTrips }) => {
  const tripsByType = {};
  Object.keys(recommendedTripsTypes).forEach((recommendationType) => {
    const trips = [];
    recommendedTrips[recommendationType].forEach((tripId) => {
      const trip = searchTrips.find((searchTrip) => searchTrip.id === tripId);
      if (trip)
        trips.push({
          ...trip,
          recommendationType,
          recommendationConcept: recommendedTripsTypes[recommendationType].concept,
        });
    });
    tripsByType[recommendationType] = trips;
  });
  return tripsByType;
};

/**
 * Track the selected recommended trip.
 *
 * @param {Object} options - Function params as object.
 * @param {string} options.way - The way.
 * @param {string} options.recommendationType - The recommendation type.
 */
export const trackRecommendedTripSelected = ({ way, recommendationType }) => {
  trackEvent(RECOMMENDED_TRIP_SELECTED, {
    way,
    dataRecommendationType: recommendationType,
    recommendationConcept: recommendedTripsTypes[recommendationType].concept,
  });
};

/**
 * Track the recommended trips load.
 *
 * @param {Object} options - Function params as object.
 * @param {string} options.way - The way.
 * @param {Array} options.recommendationsTypes - The recommendation types.
 */
export const trackRecommendedTripsLoaded = ({ way, recommendationsTypes }) => {
  trackEvent(RECOMMENDED_TRIPS_LOADED, {
    ...(recommendationsTypes && {
      'dataRecommendations': recommendationsTypes,
      'recommendationConcepts': recommendationsTypes.map(
        (recommendationType) => recommendedTripsTypes[recommendationType].concept,
      ),
      'Recommended Count': recommendationsTypes.length,
    }),
    'Recommendations Shown': Boolean(recommendationsTypes?.length),
    way,
  });
};

/**
 * Get the filtered trip list excluding the recommended trips.
 *
 * @param {Object} options - Function params as object.
 * @param {Array} options.recommendedTripsList - The list of recommended trips.
 * @param {Array} options.tripList - The original trip list.
 * @returns {Array} The filtered trip list.
 */
export const getRecommendedTripsExcludeList = ({ recommendedTripsList, tripList }) => {
  let tripListFiltered = [];
  if (recommendedTripsList.length) {
    tripListFiltered = tripList.filter(
      (trip) => !recommendedTripsList.some((recommended) => recommended.id === trip.id),
    );
  }
  return tripListFiltered;
};

/**
 * Get the recommended redirect payload.
 *
 * @param {Object} options - Function params as object.
 * @param {Object} options.trip - The trip object.
 * @returns {Object} The recommended redirect payload.
 */
export const getRecommendedRedirectPayload = ({ trip }) => {
  if (!trip?.recommendationType) return null;
  const { recommendationType } = trip;

  const payload = JSON.stringify({
    useRecommendedTrip: true,
    recommendedTripType: recommendationType,
  });
  return payload;
};

/**
 * Transform the identifier object based on the presence of 'email'.
 *
 * @param {Object} values - The input values object.
 * @param {string} [values.phone] - The phone number.
 * @param {string} [values.email] - The email address.
 * @returns {Object} The transformed identifier object.
 */
export function transformIdentifierObject(values) {
  // Determine the identifier key and value based on the presence of 'email'
  let identifierKey = 'phone';
  let identifierValue = values.phone;
  const details = {};

  if (values.email) {
    identifierKey = 'email';
    identifierValue = values.email;
  }

  // Create the details object based on dynamic keys in the original object
  Object.entries(values).forEach(([key, value]) => {
    if (key !== 'email' && key !== identifierKey) {
      details[key] = value;
    }
  });

  return {
    identifierKey,
    identifierValue,
    details,
  };
}

/**
 * Adapts passengers data by flattening array values to single values.
 *
 * @param {Array<Object>} passengers - Array of passenger objects to adapt.
 * @returns {Array<Object>} Array of adapted passenger objects with flattened values.
 */
export const adaptPassengersData = (passengers) => {
  if (passengers === null || passengers === undefined) {
    return [];
  }

  return passengers.map((passenger) => {
    const adaptedPassenger = {};

    Object.entries(passenger).forEach(([key, value]) => {
      if (key === 'nationality') {
        adaptedPassenger[key] = passenger.isoCountryCode;
      } else if (key === 'firstName' || key === 'lastName' || key === 'secondLastName') {
        let capitalizedValue = '';
        if (value) {
          const valueToCapitalize = Array.isArray(value) ? value[0] || '' : value;
          capitalizedValue = valueToCapitalize
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
        adaptedPassenger[key] = capitalizedValue;
      } else {
        adaptedPassenger[key] = Array.isArray(value) ? value[0] : value;
      }
    });

    return adaptedPassenger;
  });
};

/**
 * Format passenger name with proper capitalization.
 * @param {object} props - firstName, lastName, initialOnly
 * @param {string} props.firstName - User's first name
 * @param {string} props.lastName - User's last name, can contain multiple words
 * @param {boolean} [props.initialOnly] - If true, returns only first name and last name initial
 * @returns {string} Formatted name string
 */
export const formatPassengerName = ({ firstName, lastName, initialOnly }) => {
  if (!firstName?.trim() || !lastName?.trim()) return '';

  // When initialOnly is true, only use the first part of the first name
  const capitalizedFirst =
    firstName.split(' ')[0].charAt(0).toUpperCase() + firstName.split(' ')[0].slice(1);
  const capitalizedLast = lastName
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return initialOnly
    ? `${capitalizedFirst} ${capitalizedLast.charAt(0)}`
    : `${firstName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')} ${capitalizedLast}`;
};

/**
 * Check if the frequent passengers feature is enabled.
 * @returns {boolean} Whether the frequent passengers feature is enabled based on GrowthBook or whitelabel config.
 */
export const isFrequentsPassengerEnabled = () => {
  const featureName = 'SHOW_FREQUENT_PASSENGERS';
  const { features } = store.getState().whitelabelConfig;
  const featureValue = growthBook.getGrowthBookFeature(featureName.toLocaleLowerCase());
  return featureValue ?? features[featureName];
};
