import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, Logo, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

// Payment method logos
import banbif from '../../images/payment/yuno/banbif.png';
import bbva from '../../images/payment/yuno/bbva.png';
import bcp from '../../images/payment/yuno/bcp.png';
import cajaArequipa from '../../images/payment/yuno/caja_arequipa.png';
import cuotealo from '../../images/payment/yuno/cuotealo.png';
import disashop from '../../images/payment/yuno/disashop.png';
import interbank from '../../images/payment/yuno/interbank.png';
import kasnet from '../../images/payment/yuno/kasnet.png';
import niubuz from '../../images/payment/yuno/niubuz.png';
import pagoEfectivo from '../../images/payment/yuno/pago_efectivo.svg';
import pichincha from '../../images/payment/yuno/pichincha.png';
import plin from '../../images/payment/yuno/plin.png';
import redDigital from '../../images/payment/yuno/red_digital.png';
import safetypay from '../../images/payment/yuno/safetypay.svg';
import scotiabank from '../../images/payment/yuno/scotiabank.png';
import tambo from '../../images/payment/yuno/tambo.png';
import westernUnion from '../../images/payment/yuno/western_union.png';
import yape from '../../images/payment/yuno/yape.png';

const assets = {
  banbif,
  bbva,
  bcp,
  caja_arequipa: cajaArequipa,
  cuotealo,
  disashop,
  interbank,
  kasnet,
  niubuz,
  pago_efectivo: pagoEfectivo,
  pichincha,
  plin,
  red_digital: redDigital,
  safetypay,
  scotiabank,
  tambo,
  western_union: westernUnion,
  yape,
};

/**
 * Returns the JSX for the assets section based on the provided payment engine.
 *
 * @param {string} engine - The name of the payment engine used to determine which assets to display.
 * @param {Object} assets - An object containing imported assets, keyed by asset name, used for rendering images and icons.
 * @returns {JSX.Element|null} The JSX element representing the assets section, or `null` if no match is found.
 */
function getAssetsSection(engine, assets) {
  switch (engine) {
    case 'safetypay':
      return <Logo imgSrc={assets.safetypay} alt="Safetypay" />;
    case 'pago_efectivo':
      return (
        <Spacing alignItems="center" size="S">
          <Spacing size="XS" vertical>
            <div>
              {[
                'yape',
                'plin',
                'bbva',
                'pichincha',
                'banbif',
                'bcp',
                'caja_arequipa',
                'interbank',
                'scotiabank',
              ].map((brand) => (
                <Icon key={brand} type={assets[brand]} alt={brand} />
              ))}
            </div>
            <div className="h-3 flex items-center">
              {['niubuz', 'disashop', 'red_digital', 'kasnet', 'tambo', 'western_union'].map(
                (brand) => (
                  <Icon key={brand} type={assets[brand]} size="XL" alt={brand} />
                ),
              )}
            </div>
          </Spacing>
          <Text weight="bold">vía</Text>
          <Icon type={assets.pago_efectivo} size="XXL" alt="Pago Efectivo" />
        </Spacing>
      );
    case 'cuotealo_bcp':
      return <Logo imgSrc={assets.cuotealo} alt="Cuotealo" />;
    default:
      return null;
  }
}

function YunoForm({ engine }) {
  const { t } = useTranslation('payment');

  const header = t(`yuno_instructions.${engine}.header`);
  const content = t(`yuno_instructions.${engine}.content`);

  const assetsSection = getAssetsSection(engine, assets);

  return (
    <div className="mt-5">
      <Spacing vertical size="S">
        <Text weight="bold" size="L">
          {header}
        </Text>
        {assetsSection || ''}
        <Text size="M">{content}</Text>
      </Spacing>
    </div>
  );
}

YunoForm.propTypes = {
  engine: PropTypes.string.isRequired,
};

export default YunoForm;
