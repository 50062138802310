import { connect } from 'react-redux';
import TicketPassengers from './TicketPassengers';

/**
 * Get full passenger name, concatenating first and last name
 * @param {Object} passenger - Passenger object
 * @returns {string} - Full passenger name
 */
function getPassengerName(passenger) {
  return `${passenger.get('firstName')?.trim()} ${passenger.get('lastName')?.trim()}`;
}

/**
 * Get passenger seat for a given fragment ticket
 * @param {Object} passenger - Passenger object
 * @param {Object} tickets - List of tickets
 * @returns {string | null} - Passenger seat
 */
function getPassengerSeat(passenger, tickets) {
  const passengerId = passenger.get('id');
  const ticket = tickets.find(
    (ticket) => ticket.get('passengerId') === passengerId && !ticket.get('isAdjacentSeat'),
  );
  return ticket ? ticket.get('seat') : null;
}

/**
 * Get passenger seat level for a given fragment ticket
 * @param {Object} passenger - Passenger object
 * @param {Object} tickets - List of tickets
 * @returns {string | null} - Passenger seat level
 */
function getPassengerSeatFloor(passenger, tickets) {
  const passengerId = passenger.get('id');

  const ticket = tickets.find((ticket) => ticket.get('passengerId') === passengerId);

  return ticket?.get('seatFloor') ?? null;
}

/**
 * Get passenger seat description for a given fragment ticket
 * @param {Object} passenger - Passenger object
 * @param {Object} tickets - List of tickets
 * @returns {string | null} - Passenger seat description
 */
function getPassengerSeatFareDescription(passenger, tickets) {
  const passengerId = passenger.get('id');

  const ticket = tickets.find((ticket) => ticket.get('passengerId') === passengerId);

  return ticket?.get('seatFareDescription') ?? null;
}

/**
 * Get adjacent seat for a given passenger
 * @param {Object} passenger - Passenger object
 * @param {Object} tickets - List of tickets
 * @returns {Object} - Adjacent seat
 */
function getAdjacentSeat(passenger, tickets) {
  const ticket = tickets.find((ticket) => ticket.get('isAdjacentSeat'));
  return ticket ? ticket.toJS() : null;
}

/**
 * Get passenger seats information for a given way
 * @param {Object} passenger - Passenger object
 * @param {"departure" | "return"} way - Way of the trip (departs or returns)
 * @returns
 */
function getPassengerSeatsInfo(passenger, way) {
  if (!way || !way.has('fragments')) return [];
  const fragments = way.get('fragments');

  return fragments
    .map((fragment) => ({
      from: fragment.get('origin').get('cityName'),
      to: fragment.get('destination').get('cityName'),
      name: getPassengerName(passenger),
      seat: getPassengerSeat(passenger, fragment.get('tickets')),
      seatFloor: getPassengerSeatFloor(passenger, fragment.get('tickets')),
      seatFareDescription: getPassengerSeatFareDescription(passenger, fragment.get('tickets')),
      // TODO refactorizar para que el passenger tenga seats en vez de separar el adjacentSeat como tal
      adjacentSeat: getAdjacentSeat(passenger, fragment.get('tickets')),
    }))
    .toJS();
}

const mapStateToProps = ({ purchase }) => {
  const passengers = purchase
    .get('passengers')
    .map((passenger) => ({
      name: passenger.get('name'),
      type: passenger.get('busCategory'),
      departureSeats: getPassengerSeatsInfo(passenger, purchase.get('departs')),
      returnSeats: getPassengerSeatsInfo(passenger, purchase.get('returns')),
    }))
    .toJS();

  return {
    passengers,
    isOpenTicket: purchase.get('openTicket'),
  };
};

export default connect(mapStateToProps)(TicketPassengers);
