import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button, FlatButton, Spacing, Text, Currency } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import usePurchase from 'hooks/store/usePurchase';
import { encodePassengers } from 'utils/Reserbus';
import { buildSearchUrl } from 'utils/urls';
import { priceChangeModalTracker } from 'metrics/user-analytics/purchase';
import usePricingBeforeCheckout from 'hooks/usePricingBeforeCheckout';

/**
 * Modal component that shows the new price when the price changes
 * Changes based on original price and new price.
 * Price modifiers are flatfare, coupons, dynamic prices, etc.
 * @returns {JSX.Element} - Modal price change component
 */
const DynamicPriceModal = () => {
  const { t } = useTranslation('purchase');
  const features = useWhitelabelFeatures();
  const history = useHistory();
  const purchase = usePurchase();

  const {
    departs,
    returns,
    passengerSelection,
    externalCouponCode,
    total,
    totalBeforeLock,
    walletBalanceUsed,
    isExchange,
    roundTrip,
    isUpdating,
    wantsFlatFare,
    flatFareAvailable,
    totalTaxes,
  } = purchase;
  const hasDiscount = !!externalCouponCode;
  const formerPrice = totalBeforeLock;
  const { departure, origin, destination } = departs;
  const encodedPassengersSelection = encodePassengers(passengerSelection) || 'A1';
  const [visible, setVisible] = useState(true);
  const { departTripPricing } = usePricingBeforeCheckout({ isRoundTrip: roundTrip });
  const { brand } = useWhitelabelEnvs();

  /**
   * Function to close the modal changing the state
   */
  const closeModal = () => {
    priceChangeModalTracker('Continue');
    setVisible(false);
  };

  /**
   * Function to redirect to the search page with the same parameters
   * Used when the user wants to search for another schedule after the price change
   */
  const handleGotoSearch = () => {
    const url = buildSearchUrl(
      origin.id,
      destination.id,
      moment(departure).format('DD-MMM-YY'),
      roundTrip ? moment(returns?.departure).format('DD-MMM-YY') : null,
      encodedPassengersSelection,
    );

    priceChangeModalTracker('View Results');
    if (isExchange) {
      history.push('/exchange/schedule');
    }
    history.replace(url);
  };

  /**
   * Centralized business rules to hide the modal
   * @returns {boolean} - True if the modal should be hidden
   */
  const hideModalByBusinessRules = () => {
    /**
     * If a coupon is applied, the modal should be hidden
     */
    if (hasDiscount) return true;

    /**
     * If wallet balance is used and it covers the total, the modal should be hidden
     */
    if (walletBalanceUsed + total === totalBeforeLock) return true;
    /**
     * If the total is the same as the former price, the modal should be hidden
     * Means that the price was not changed
     */
    if (total === formerPrice) return true;

    /**
     * If the total equals the total before lock plus the total taxes, the modal should be hidden
     */
    if (features.SHOW_TICKET_PRICE_WITHOUT_TAX && total === totalBeforeLock + totalTaxes)
      return true;

    return false;
  };

  const isPriceLower = total < formerPrice;
  const title = isPriceLower
    ? `${t('purchase:label.price_lowered')} 🎉`
    : `${t('purchase:label.price_changed')} ⚠️`;
  let description = t('purchase:text.discounts_off_description', { context: brand });
  if (departTripPricing.discountType === 'dynamic_pricing') {
    description = t('purchase:text.discount_availability_description');
  }
  if (flatFareAvailable && wantsFlatFare) {
    description = t('purchase:text.flat_fare_availability_description');
  }

  /**
   * Evaluates feature is enabled by feature flag, and if the price is lower, evaluates with it's corresponding feature flag
   * @param {object} param0 - Params object
   * @param {boolean} param0.isPriceLower - True if the price has lowered
   * @returns {boolean} - True if the modal should be hidden
   */
  const hideModalByFeature = ({ isPriceLower }) => {
    if (!features.PRICE_CHANGED_MODAL_ENABLED) return true;
    if (isPriceLower) return !features.PRICE_CHANGED_MODAL_LOWER_PRICE_ENABLED;
    return false;
  };

  if (hideModalByFeature({ isPriceLower }) || !visible || isUpdating || hideModalByBusinessRules())
    return null;

  return (
    <Modal
      title={title}
      showCloseButton={false}
      closeIcon={false}
      closeOnOverlayClick={false}
      responsiveSize="auto"
      contentHeight="auto"
    >
      <Spacing vertical alignItems="center" justifyContent="center">
        <Text>{description}</Text>

        <Spacing vertical size="XS">
          <Spacing justifyContent="center" size="XS">
            <Text size="XL" weight="bold" color={isPriceLower ? 'success' : 'gray600'}>
              {t('label.new_total')}
            </Text>

            <Currency
              color={isPriceLower ? 'success' : 'gray600'}
              price={total}
              size="XL"
              weight="bold"
            />
            <Text size="XL" weight="bold" color={isPriceLower ? 'success' : 'gray600'}>
              💵
            </Text>
          </Spacing>

          <Spacing justifyContent="center" size="XS">
            <Text size="XS" color="grayMedium">
              {t('label.former_price')}
            </Text>

            <Currency color="grayMedium" price={formerPrice} size="XS" />
          </Spacing>
        </Spacing>

        <Button
          buttonType="button"
          borderRadius="M"
          variant="accent"
          withHeight
          text={t('purchase:label.continue_buying')}
          fullWidth
          onClick={closeModal}
        />

        <FlatButton
          size="S"
          text={t('purchase:label.go_back_to_choose_another_schedule')}
          type="primary"
          newTab
          textDecorationLine="none"
          onClick={handleGotoSearch}
        />
      </Spacing>
    </Modal>
  );
};

export default DynamicPriceModal;
