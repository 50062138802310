import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Text, Box, Spacing } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { useTranslation } from 'react-i18next';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    }),
  ).isRequired,
  permanency: PropTypes.string,
  distance: PropTypes.string,
  hasNextDayBadge: PropTypes.bool,
};

/**
 * Represents a stop item in the timeline.
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the stop item.
 * @param {string} props.description - The description of the stop item.
 * @param {Array} props.time - The array of time segments for the stop item.
 * @param {string} props.permanency - The permanency of the stop item.
 * @param {string} props.distance - The distance of the stop item.
 * @param {bool} props.hasNextDayBadge - Indicates if the stop item has a next day badge.
 */
const StopItem = ({ title, description, time, permanency, distance, hasNextDayBadge }) => {
  const { t } = useTranslation('trips');
  const timeSegments = time.map(({ title, time, nextDayBadge }, index) => {
    const key = `${time}-${index}`;
    return (
      <div key={key}>
        <Text size="S">{title}</Text>
        <Text size="S">{time}</Text>
        {nextDayBadge ? (
          <Badge type="accent" roundCorners>
            <Text color="white" size="S" weight="semibold" italic elementType="span">
              {t('next_day')}
            </Text>
          </Badge>
        ) : null}
      </div>
    );
  });

  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;

  return (
    <div className="route-detail-row stopover">
      <div className={`rd-left ${hasNextDayBadge ? 'with-next-day' : ''}`}>{timeSegments}</div>

      <div className="rd-center">
        <i className="ic-stopover" />
      </div>

      <div className="rd-right bus">
        <Box
          paddingVertical="S"
          paddingHorizontal="M"
          borderRadius="S"
          border="all"
          borderColor="grayBorder"
          alphaBg="M"
          bgColor="grayBorder"
          hasWidth
        >
          <Spacing size="XS" vertical>
            <Text weight="bold" size="S">
              {title}
            </Text>
            {showTerminal && <Text size="S">{description}</Text>}
            {distance && (
              <Text size="S">
                {t('scale')}: {distance}km
              </Text>
            )}
            {permanency && (
              <Text size="S">
                {t('permanency')}: {permanency}
              </Text>
            )}
          </Spacing>
        </Box>
      </div>
    </div>
  );
};

StopItem.propTypes = propTypes;

export default StopItem;
