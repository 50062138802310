import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getExpirationTime = (expiresAt, currentTime, isExpired) => {
  if (isExpired || !expiresAt) return;

  const purchaseCurrentTime = currentTime;
  const currentTimeMoment = moment(purchaseCurrentTime, 'x');
  const expireTime = moment(expiresAt, 'x');
  const remainingTime = expireTime.diff(currentTimeMoment, 'seconds', true);

  return remainingTime;
};

/**
 * Creates the format for the timer
 * @param {Number} time - time in seconds to format
 * @param {String} format - format to use
 */
export function createTimerFormat(time, format) {
  return moment.duration(time, 'seconds').format(format, { trim: false });
}

/**
 * Determines the period of the day based on the hour
 * @param {number} hour - Hour in 24-hour format
 * @returns {string} Period identifier ('EarlyMorning', 'Morning', 'Afternoon', or 'Night')
 */
const getDayPeriod = (hour) => {
  const normalizedHour = hour % 24;
  if (normalizedHour >= 0 && normalizedHour < 6) return 'EarlyMorning';
  if (normalizedHour >= 6 && normalizedHour < 12) return 'Morning';
  if (normalizedHour >= 12 && normalizedHour < 18) return 'Afternoon';
  return 'Night';
};

/**
 * Processes an array of trips to obtain an array of objects with formatted time,
 * day period and id
 * @param {Array<Object>} nextDepartures - Array of next departures objects with tripId as key
 * @returns {Array<Object>} Array of objects with time, period and id
 */
export const processNextDepartures = (nextDepartures) => {
  // Validate that nextDepartures is an array and has elements
  if (!Array.isArray(nextDepartures) || !nextDepartures.length) return [];

  // Process all groups and their departures
  return nextDepartures.reduce((acc, departureGroup) => {
    // Validate that the group has departures
    if (!Array.isArray(departureGroup.departures) || !departureGroup.departures.length) {
      return acc;
    }

    // Process departures for current trip
    const groupDepartures = departureGroup.departures.map((timestamp) => {
      const momentObj = moment(timestamp);
      return {
        moment: timestamp,
        time: momentObj.format('h:mm a').toUpperCase(),
        period: getDayPeriod(momentObj.hours()),
        id: departureGroup.id,
      };
    });

    return [...acc, ...groupDepartures];
  }, []);
};
