import { useFeatureValue } from '@growthbook/growthbook-react';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';

/**
 * Custom Hook to get a feature value
 * @param {string} featureName - The feature name without the brand.
 * @returns {boolean | string | object | null} - The feature value.
 */
const useGrowthBookFeatureValue = (featureName) => {
  const { brand } = useWhitelabelEnvs();

  const featureValue = useFeatureValue(`${featureName}_${brand.toLowerCase()}`);
  return featureValue;
};

export default useGrowthBookFeatureValue;
