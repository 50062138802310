// @ts-check

/**
 * @typedef {import('./BnplStrategy').BnplStrategy} BnplStrategy
 * @typedef {import('./BnplStrategy').BnplInfo} BnplInfo
 * @typedef {import('./BnplStrategy').EnginePayload} EnginePayload
 */

/**
 * KueskiPay BNPL strategy.
 * @function KueskiPayBnplStrategy
 * @returns {BnplStrategy}
 */
function KueskiPayBnplStrategy() {
  /**
   * Create payload for KueskiPay BNPL.
   * @param {BnplInfo} bnplInfo - BNPL information.
   * @param {EnginePayload} enginePayload - Additional payload specific to the engine.
   * @returns {Promise<Object>} The created payload.
   */
  const createPayload = async (bnplInfo, enginePayload = {}) => {
    return {
      payment_type: bnplInfo.payment_type,
      payment_engine: bnplInfo.payment_engine,
      payment_provider: bnplInfo.payment_provider,
      tracker_id: bnplInfo.tracker_id,
      device_session_id: bnplInfo.device_session_id,
      monthly_selected_plan: bnplInfo.monthly_selected_plan,
      ...enginePayload,
    };
  };

  return { createPayload };
}

const kueskiPayBnplStrategy = KueskiPayBnplStrategy();

export default kueskiPayBnplStrategy;
