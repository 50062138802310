import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Text,
  Spacing,
  SelectableItem,
  Box,
  FlatButton,
} from '@reservamos/elements';
import useFrequentPassengersContext from 'context/FrequentPassengers';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { formatPassengerName } from 'utils/userPreferences';
import passengerTypes from '../config/passengerTypes';

/**
 * Renders the FrequentPassengerModal component, which displays passenger information,
 * including the passenger's name, type, category icon, and seat selection details.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.typePassenger='general'] - The type of passenger, which determines the displayed icon and category.
 *    It can be one of the following values:
 *    - "minor" "older" "general" "student""teacher" "wheelchair_handicap" "special""pet_friendly"
 *
 * @returns {JSX.Element} The rendered FrequentPassengerModal component, which shows the passenger name, type, icon, and seat(s) information.
 */
const FrequentPassengerModal = ({ typePassenger, passengerIndex, onClose }) => {
  const {
    frequentPassengers,
    selectFrequentPassenger,
    removeFrequentPassenger,
    pendingPassengerDeletions,
    undoDeleteFrequentPassenger,
  } = useFrequentPassengersContext();
  const { t } = useTranslation('passengers');
  const features = useWhitelabelFeatures();
  const selectedFrequentPassengers = frequentPassengers
    .filter((passenger) => passenger.isSelected)
    .sort((a, b) => a.selectedForPassenger - b.selectedForPassenger);
  const unselectedFrequentPassengers = frequentPassengers.filter(
    (passenger) => !passenger.isSelected,
  );
  const [selectedFrequentPassenger, setSelectedFrequentPassenger] = useState({});

  const setInitialModalState = () => {
    setSelectedFrequentPassenger(
      frequentPassengers.find((passenger) => passenger.selectedForPassenger === passengerIndex) ||
        {},
    );
  };

  useEffect(() => {
    setInitialModalState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequentPassengers, passengerIndex]);

  const { icon, iconVariant } = passengerTypes[typePassenger];
  const isFlat = features.FUNNEL_STYLE === 'FLAT';

  const onConfirmDeletePassenger = (passengerId) => {
    removeFrequentPassenger(passengerId);
  };

  const onConfirmSelectPassenger = (passengerId) => {
    selectFrequentPassenger(passengerId, passengerIndex, 'Modal');
    onClose();
  };

  return (
    <Modal
      headerColor={isFlat ? '' : 'primary'}
      iconType={isFlat ? iconVariant : icon}
      responsiveSize="auto"
      contentHeight="auto"
      removeContentPadding
      onCloseModal={onClose}
      title={t('frequent_passengers.title', { numberPassenger: passengerIndex })}
      footer={
        <Box
          paddingHorizontal="M"
          paddingVertical="L"
          paddingHorizontalMobile="S"
          paddingVerticalMobile="M"
        >
          <Button
            text={t('frequent_passengers.confirm_button', { numberPassenger: passengerIndex })}
            variant="primary"
            color="white"
            padding="XL"
            mobilePadding="XL"
            borderRadius="M"
            fullWidth
            onClick={() => onConfirmSelectPassenger(selectedFrequentPassenger.id)}
          />
        </Box>
      }
    >
      <Box
        paddingHorizontal="M"
        paddingVertical="L"
        paddingHorizontalMobile="S"
        paddingVerticalMobile="M"
      >
        <Spacing size="L" vertical>
          <Spacing size="S" vertical>
            <Text weight="semibold">{t('frequent_passengers.current_subtitle')}</Text>

            {selectedFrequentPassengers.map((passenger) => {
              const isSelected = selectedFrequentPassenger.id === passenger.id;
              const label = `${t('frequent_passengers.passenger_label')} ${
                passenger.selectedForPassenger
              }`;

              return (
                <SelectableItem
                  key={passenger.id}
                  iconType={
                    isFlat
                      ? passengerTypes[passenger.category].iconVariant
                      : passengerTypes[passenger.category].icon
                  }
                  label={label}
                  text={formatPassengerName({
                    firstName: passenger.firstName,
                    lastName: passenger.lastName,
                  })}
                  isSelected={isSelected}
                  onClick={() => setSelectedFrequentPassenger(passenger)}
                  onDelete={() => onConfirmDeletePassenger(passenger.id)}
                />
              );
            })}
          </Spacing>

          <Spacing size="S" vertical>
            <Text weight="semibold">{t('frequent_passengers.frequent_subtitle')}</Text>

            {unselectedFrequentPassengers.map((passenger) => {
              const isSelected = selectedFrequentPassenger.id === passenger.id;
              const label = isSelected
                ? t('frequent_passengers.selection_text', { numberPassenger: passengerIndex })
                : '';
              return (
                <SelectableItem
                  key={passenger.id}
                  iconType={
                    isFlat
                      ? passengerTypes[passenger.category].iconVariant
                      : passengerTypes[passenger.category].icon
                  }
                  label={label}
                  text={formatPassengerName({
                    firstName: passenger.firstName,
                    lastName: passenger.lastName,
                  })}
                  isSelected={isSelected}
                  onClick={() => setSelectedFrequentPassenger(passenger)}
                  onDelete={() => onConfirmDeletePassenger(passenger.id)}
                />
              );
            })}
          </Spacing>

          <Spacing size="S" vertical>
            {pendingPassengerDeletions.current.map((deletion) => {
              return (
                <Spacing key={deletion.passenger.id} size="S">
                  <Text key={deletion.passenger.id}>
                    {t('frequent_passengers.deleting_passenger', {
                      passengerName: formatPassengerName({
                        firstName: deletion.passenger.firstName,
                        lastName: deletion.passenger.lastName,
                      }),
                    })}{' '}
                  </Text>
                  <FlatButton
                    text={t('frequent_passengers.undo_deleting_passenger')}
                    onClick={() => undoDeleteFrequentPassenger(deletion.passenger.id)}
                  />
                </Spacing>
              );
            })}
          </Spacing>
        </Spacing>
      </Box>
    </Modal>
  );
};

FrequentPassengerModal.propTypes = {
  typePassenger: PropTypes.oneOf(Object.keys(passengerTypes)),
  passengerIndex: PropTypes.number,
  onClose: PropTypes.func,
};

FrequentPassengerModal.defaultProps = {
  typePassenger: 'general',
  passengerIndex: 1,
  onClose: () => {},
};

export default FrequentPassengerModal;
