// @ts-check

import mercadoPagoWalletStrategy from '../../methods/wallet/strategies/MercadoPagoWalletStrategy';
import walletMethod from '../../methods/wallet/Wallet';

/**
 * @typedef {import('./Wallet.js').Wallet} Wallet
 */

const engines = {
  mercadopago: mercadoPagoWalletStrategy,
};

/**
 * Factory to create instances of Wallet with a given engine.
 * @returns {object} An object with the create method to instantiate Wallet.
 */
function WalletFactory() {
  /**
   * Create an instance of Wallet with the given engine.
   * @param {object} config - Configuration with the engine.
   * @param {string} config.engine - The payment engine to use.
   * @returns {Wallet} An instance of Wallet configured with the appropriate strategy.
   */
  function create({ engine }) {
    const strategy = engines[engine];

    walletMethod.setStrategy(strategy);
    return walletMethod;
  }

  return { create };
}

const walletFactory = WalletFactory();

export default walletFactory;
