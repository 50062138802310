import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import valid from 'card-validator';
import { Field, getFormSyncErrors, getFormValues, change as changeFormField } from 'redux-form';
import { fieldRenderer, selectRenderer } from 'utils/formRenderers';
import cybersourceLogo from 'images/payment/cybersource.png';
import { Alert, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PaymentImage from '../../../ui/atoms/PaymentImage';
import useReactNativeMessage from '../../../hooks/useReactNativeMessage';
import Installments from '../Installments';
import CardsLogos from '../CardsLogos';

const CURRENT_YEAR = moment().year();

/**
 * Get the list of expiration months.
 *
 * @returns {Array} The list of expiration months.
 */
function getExpirationMonths() {
  return moment.months().map((monthName, index) => {
    const monthNumber = `0${index + 1}`.slice(-2);

    return {
      display: `${monthNumber} - ${monthName}`,
      value: monthNumber,
    };
  });
}

/**
 * Get the list of expiration years.
 *
 * @returns {Array} The list of expiration years.
 */
function getExpirationYears() {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => CURRENT_YEAR + count);
}

/**
 * Generic card form to use when a payment engine does not provide a specific form.
 *
 */
const CardForm = () => {
  const { t } = useTranslation('payment');
  const { env, features } = useSelector((state) => state.whitelabelConfig);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const purchaserFieldsSyncErrors = getFormSyncErrors('card')(state);
  const purchaserFormValues = getFormValues('card')(state);
  const fieldHolderName = useRef();
  const fieldCardNumber = useRef();
  const fieldExpirationMonth = useRef();
  const fieldExpirationYear = useRef();
  const fieldCVV = useRef();
  const RNMessage = useReactNativeMessage();

  useEffect(() => {
    if (
      !purchaserFieldsSyncErrors.cardNumber &&
      purchaserFormValues.cardNumber &&
      !purchaserFormValues.cardBrand
    ) {
      const cardBrand = valid.number(purchaserFormValues.cardNumber).card.type;
      dispatch(changeFormField('card', 'cardBrand', cardBrand));
    }

    if (purchaserFieldsSyncErrors.cardNumber && purchaserFormValues.cardBrand) {
      dispatch(changeFormField('card', 'cardBrand', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaserFieldsSyncErrors, purchaserFormValues]);

  useEffect(() => {
    RNMessage.post({ formLoaded: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const monthsOptions = getExpirationMonths().map((option) => ({
    value: option.value,
    label: option.value,
  }));
  const yearsOptions = getExpirationYears().map((year) => ({ value: year, label: year }));

  return (
    <>
      {features.SHOW_CARD_ACTIVE_MEMO && (
        <Alert
          alertType="info"
          title={t('title_card_memo')}
          weightTitle="semibold"
          titleSize="S"
          iconSize="S"
        >
          <Text size="S">{t('text_card_memo')}</Text>
        </Alert>
      )}
      <div className="card-form">
        {features.INSTALLMENTS_ENABLED && <Installments />}
        <Field type="hidden" name="monthlySelectedPlan" component="input" />
        <Field type="hidden" name="cardBrand" component="input" />

        {features.SHOW_CREDIT_DEBIT_LOGOS && (
          <CardsLogos availableCards={features.CYBERSOURCE_AVAILABLE_CARD_BRANDS} />
        )}

        <div className="checkout-section">
          <Spacing vertical size="XS">
            {features.BILLING_ADDRESS_ENABLED && (
              <Field
                id="billingAddress"
                type="text"
                name="billingAddress"
                placeholder={t('billing_address')}
                component={fieldRenderer}
              />
            )}

            <Field
              ref={fieldCardNumber}
              id="cardNumber"
              type="tel"
              name="cardNumber"
              placeholder={t('card_number')}
              maxLength="16"
              component={fieldRenderer}
            />

            <p className="heading-form-text">{t('card_expiration')}</p>
            <Spacing flexGrow>
              <Field
                ref={fieldExpirationMonth}
                id="expirationMonth"
                name="expirationMonth"
                cls="form-item-wrap-l fs-exclude"
                component={selectRenderer}
                defaultValue={t('card_month')}
                options={monthsOptions}
                placeholder={t('card_month')}
              />

              <Field
                id="expirationYear"
                ref={fieldExpirationYear}
                name="expirationYear"
                component={selectRenderer}
                options={yearsOptions}
                placeholder={t('card_year')}
              />
            </Spacing>
            <Spacing alignItems="end">
              <Field
                id="cvv2"
                ref={fieldCVV}
                type="password"
                name="cvv2"
                placeholder={t('card_CVV')}
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength="4"
                component={fieldRenderer}
                defaultValue={t('card_year')}
              />

              <PaymentImage type="cvv" />
            </Spacing>

            <Field
              ref={fieldHolderName}
              id="holderName"
              type="text"
              name="holderName"
              placeholder={t('card_owner_name')}
              component={fieldRenderer}
            />

            {env.cybersource && env.cybersource.enabled ? (
              <Spacing justifyContent="flex-end">
                <img src={cybersourceLogo} height="25px" width="110px" />
              </Spacing>
            ) : null}
          </Spacing>
        </div>
      </div>
    </>
  );
};

export default CardForm;
