import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@reservamos/elements';

/**
 * CleanButton component.
 * Render a clean button with an icon.
 *
 * @param {object} props - onClick, disabled, className, ariaLabel, type.
 * @param {function} props.onClick - The function to be called on button click.
 * @param {boolean} props.disabled - Indicates if the button is disabled.
 * @param {string} props.className - The CSS class for styling the button.
 * @param {string} props.ariaLabel - The ARIA label for accessibility.
 * @param {string} props.type - The type of the button (button, submit, reset).
 *
 * @returns {JSX.Element} A button component with an icon.
 */
const CleanButton = ({ onClick, disabled, className, ariaLabel, type }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      title={ariaLabel}
      className={`w-[35px] h-[35px] rounded-full transition duration-300 bg-primary-regular/10 flex items-center justify-center hover:bg-primary-regular/80 focus:outline-dashed focus:outline-2 focus:outline-offset-2 focus:outline-primary-regular disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon type="Clean" size="S" color={isHovered ? 'white' : 'primaryStrong'} />
    </button>
  );
};

CleanButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

CleanButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: '',
  ariaLabel: 'Clean seat',
  type: 'button',
};

export default CleanButton;
