import React from 'react';
import { Box, Spacing, Text, Icon } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import grayLogo from 'images/payment/mercado-pago/logo-gray.svg';
import BadgeNew from '../../ui/atoms/BadgeNew';
import MercadoPagoIcons from '../../ui/templates/MercadoPagoIcons';
import PaymentImage from '../../ui/atoms/PaymentImage';

const MercadoPagoForm = () => {
  const { t } = useTranslation('payment');

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <Spacing alignItems="center">
          <PaymentImage type="mercadopago-logo" />
          <BadgeNew />
        </Spacing>

        <Text size="S">{t('mercadopago_instructions.first')}</Text>
        <MercadoPagoIcons />
        <Box
          bgColor="grayBorder"
          borderRadius="M"
          paddingHorizontal="S"
          paddingVertical="XS"
          alphaBg="M"
        >
          <Spacing alignItems="center">
            <Icon type={grayLogo} size="L" />
            <Text size="S">{t('mercadopago_instructions.second')}</Text>
          </Spacing>
        </Box>
      </Spacing>
    </div>
  );
};

export default MercadoPagoForm;
