import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LoadingScreen from 'components/LoadingScreen';
import useUserPreferences from 'hooks/useUserPreferences';

const propTypes = {
  token: PropTypes.string.isRequired,
  paymentId: PropTypes.number.isRequired,
  type: PropTypes.string,
  using3dSecure: PropTypes.bool,
  paymentRedirectUrl: PropTypes.string,
  paymentStatus: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  getPayment: PropTypes.func.isRequired,
  transitionTo: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  pathToMobile: PropTypes.string,
  paymentEngine: PropTypes.string,
  hasPreSelectedSeats: PropTypes.bool,
};

const defaultProps = {
  type: '',
  using3dSecure: false,
  paymentRedirectUrl: '',
};

/**
 * PaymentProcessor component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.getPayment - The function to get payment.
 * @param {boolean} props.isFetching - Flag indicating if the payment is being fetched.
 * @param {boolean} props.loaded - Flag indicating if the payment is loaded.
 * @param {number} props.paymentId - The payment ID.
 * @param {string} props.paymentRedirectUrl - The payment redirect URL.
 * @param {string} props.paymentStatus - The payment status.
 * @param {string} props.token - The payment token.
 * @param {Function} props.transitionTo - The function to transition to a new route.
 * @param {Function} props.setError - The function to set an error.
 * @param {string} props.pathToMobile - The path to the mobile version.
 * @param {string} props.type - The payment type.
 * @param {string} props.using3dSecure - The payment used 3d secure.
 * @param {string} props.paymentEngine - The payment engine.
 * @param {boolean} props.hasPreSelectedSeats - Flag indicating if pre-selected seats are available.
 */
const PaymentProcessor = ({
  getPayment,
  isFetching,
  loaded,
  paymentId,
  paymentRedirectUrl,
  paymentStatus,
  token,
  transitionTo,
  type,
  using3dSecure,
  setError,
  pathToMobile,
  paymentEngine,
  hasPreSelectedSeats,
}) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const { passengers } = useUserPreferences();

  const cardRedirect = useCallback(() => {
    const purchaseUrl = `${window.location.protocol}//${env.frontline.host}/purchases/${token}`;
    const hasFrequentPassengers = Boolean(passengers.length);

    if (using3dSecure && paymentStatus !== 'failed') {
      window.location = paymentRedirectUrl;
    } else {
      switch (paymentStatus) {
        case 'attempt':
          if (paymentEngine === 'evertec') {
            transitionTo(`/purchase/${token}/complete`);
          }
          break;
        case 'charged':
          transitionTo(
            `/purchase/${token}/complete?trackPurchase=true&seatsOnResults=${Boolean(
              hasPreSelectedSeats,
            )}&frequentPassengers=${hasFrequentPassengers}`,
          );
          break;
        case 'suspicious':
          window.location = `${purchaseUrl}/pending`;
          break;
        case 'failed':
          if (paymentEngine === 'evertec') {
            transitionTo(`/payment/${token}/canceled`);
          } else {
            transitionTo(`/payment/${token}/${paymentId}/failure`);
          }
          break;
        case 'rejected':
          transitionTo(`/payment/${token}/${paymentId}/failure`);
          break;
        case 'chargeback':
          setError(301, 'error_when_generating_purchase', 'error', true);
          break;
        default:
          transitionTo(`${pathToMobile}/purchase/${token}/checkout`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentId,
    paymentRedirectUrl,
    paymentStatus,
    token,
    transitionTo,
    using3dSecure,
    passengers,
  ]);

  const redirectToRoute = useCallback(() => {
    switch (type) {
      case 'paypal':
        window.location = paymentRedirectUrl;
        break;

      case 'third_party':
        window.location = paymentRedirectUrl;
        break;

      case 'store':
        transitionTo(`/purchase/${token}/complete`);
        break;

      case 'credit_card':
      case 'reservamos_pay':
      case 'free_pay':
        cardRedirect();
        break;

      case 'exchange':
        cardRedirect();
        break;

      default:
        transitionTo(`${pathToMobile}/purchase/${token}/checkout`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loaded && !isFetching) {
      getPayment();
    } else {
      redirectToRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    redirectToRoute();
  }, [isFetching, redirectToRoute]);

  if (!loaded || paymentStatus === 'attempt' || paymentStatus === 'chargeback')
    return <LoadingScreen />;

  if (type === 'paypal') return <LoadingScreen loadingText="redirecting_to_paypal" />;

  if (type === 'third_party') return <LoadingScreen loadingText="redirecting_to_coppel_pay" />;

  return <LoadingScreen />;
};

PaymentProcessor.propTypes = propTypes;
PaymentProcessor.defaultProps = defaultProps;

export default PaymentProcessor;
