import { connect } from 'react-redux';
import moment from 'moment';
import { formatCurrency } from 'utils/Helpers';
import getPassengerCount from 'utils/purchase/getPassengerCount';
import getPurchaseStepName from 'utils/purchase/getPurchaseStepName';
import { getPurchase } from '@/actions/purchase';
import { togglePurchaseReviewOverlay } from '@/actions/overlayView';
import { toggleModal } from '@/actions/index';
import { encodePassengers } from 'utils/Reserbus';
import Purchase from './Purchase';

const mapStateToProps = (state, ownProps) => {
  const { payment, purchase, overlayView, search } = state;

  const currentPurchaseStep = getPurchaseStepName(ownProps.location.pathname);
  const originCity = purchase.getIn(['departs', 'fragments', 0, 'origin', 'cityName']);
  const originSlug = purchase.getIn(['departs', 'fragments', 0, 'origin', 'cityId']);
  const originId = purchase.getIn(['departs', 'fragments', 0, 'origin', 'id']);
  const destinationId = purchase.getIn(['departs', 'fragments', 0, 'destination', 'id']);
  const destinationCity = purchase.getIn(['departs', 'fragments', 0, 'destination', 'cityName']);
  const destinationSlug = purchase.getIn(['departs', 'fragments', 0, 'destination', 'cityId']);
  const roundTrip = purchase.get('roundTrip');
  const passengerCount = getPassengerCount(
    purchase.get('passengers'),
    purchase.get('passengerSelection'),
  );
  const paymentPlan = purchase.get('selectedInstallmentsPlan').toObject();
  const paymentType = purchase.get('selectedPaymentOption');
  const isUpdating =
    purchase.getIn(['departs', 'isLockingTickets']) ||
    purchase.getIn(['returns', 'isLockingTickets']) ||
    purchase.get('isUpdating') ||
    purchase.get('updatingInsurance') ||
    purchase.get('updatingCarbonOffset') ||
    purchase.get('applyingDiscount') ||
    purchase.get('updatingServiceLevel');
  let totalPrice = purchase.get('total') || 0;
  let headerTitle;
  const departureTrip = purchase.get('departs');
  const tripDate = moment(departureTrip.get('departure'));

  // Dynamic back button matching to tenant type of search [city, terminal, mixed]
  const typeOfSearch = purchase.getIn(['tenant', 'typeOfSearch']);
  let findableOriginId;
  let findableDestinationId;
  if (typeOfSearch === 'terminal') {
    findableOriginId = originId;
    findableDestinationId = destinationId;
  } else {
    findableOriginId = originSlug;
    findableDestinationId = destinationSlug;
  }

  switch (currentPurchaseStep) {
    case 'passengers':
      headerTitle = 'data_for_purchase';
      break;
    case 'dSeats':
      headerTitle = roundTrip ? 'choose_your_departing_seats' : 'choose_your_seats';
      break;
    case 'rSeats':
      headerTitle = 'choose_your_return_seats';
      break;
    case 'checkout':
      headerTitle = 'payment';
      break;
    default:
      headerTitle = 'purchase';
  }

  if (paymentType === 'credit_card' && paymentPlan.months > 1) {
    totalPrice = paymentPlan.total;
  }
  const { expirationExtension } = purchase.toJS();
  const {
    available: expirationExtensionAvailable,
    minutesToExtend,
    secondsBeforeExpiration,
  } = expirationExtension || {};

  const paymentLoaded = purchase.get('lastPaymentId') ? payment.get('loaded') : true;

  const passengerSelection = purchase.get('passengerSelection')?.toJS();
  const encodedPassengers = (passengerSelection && encodePassengers(passengerSelection)) || 'A1';

  return {
    currentPurchaseStep,
    destinationCity,
    destinationSlug,
    headerTitle,
    originCity,
    originSlug,
    passengerCount,
    paymentType,
    departureIsOpenTicket: purchase.getIn(['departs', 'fragments', 0, 'openTicket']),
    returnIsOpenTicket: purchase.getIn(['returns', 'fragments', 0, 'openTicket']),
    searchDepartureDate: search.get('departureDate'),
    searchReturnsDate: search.get('returnDate'),
    departureDate: purchase.getIn(['departs', 'departure']),
    arrivalDate: purchase.getIn(['departs', 'arrival']),
    availablePayments: purchase.get('availablePayments').toArray(),
    fetchingPayment: payment.get('isFetching') || false,
    isUpdating: Boolean(isUpdating),
    loaded: purchase.get('loaded') && paymentLoaded,
    purchaseReviewVisible: overlayView.get('purchaseReviewVisible'),
    returnDate: purchase.getIn(['returns', 'departure']),
    totalPrice: formatCurrency(totalPrice, 2),
    paymentId: payment.get('id'),
    paymentEngineType: payment.get('paymentType'),
    paymentStatus: payment.get('status'),
    paymentEngine: purchase.get('paymentEngine'),
    purchaseStatus: payment.get('purchaseState'),
    token: purchase.get('token'),
    isExchange: purchase.get('isExchange'),
    // TODO: Move confirm itinerary to middleware, make purchase review stateful
    purchase: purchase.toJS(),
    roundTrip,
    originId,
    destinationId,
    tripDate,
    expirationExtensionAvailable,
    minutesToExtend,
    secondsBeforeExpiration,
    findableOriginId,
    findableDestinationId,
    encodedPassengers,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPurchase: () => dispatch(getPurchase(ownProps.match.params.token)),

  showModal: (component, extraProps) => dispatch(toggleModal('visible', component, extraProps)),

  onTogglePurchaseReview: (visible) => dispatch(togglePurchaseReviewOverlay(visible)),

  transitionTo: (route) => ownProps.history.replace(route),
});

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
