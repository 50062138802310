import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { toggleRenderer } from '../utils/formRenderers';
import InsuranceModal from './InsuranceModal';
import { formatCurrency } from '../utils/Helpers';
import insuranceETN from '../images/brands-unique/etn/insuranceETN.png';
import 'styles/components/CardCheckbox';

/**
 * Insurance toggle component for passengers
 * @param {Object} props - Component props
 * @param {string} props.passenger - Passenger identifier
 * @param {number} props.insuranceOutgoingUnitAmount - Cost of outgoing insurance
 * @param {number} props.insuranceIncomingUnitAmount - Cost of incoming insurance
 * @param {boolean} props.isExchange - Whether this is an exchange trip
 * @param {boolean} props.wantsOutgoingInsurance - Whether passenger wants outgoing insurance
 * @param {boolean} props.wantsIncomingInsurance - Whether passenger wants incoming insurance
 * @param {boolean} props.isLoading - Whether the toggle is in loading state
 * @returns {React.Component} - Rendered component
 */
const PassengerInsuranceToggle = ({
  passenger,
  insuranceOutgoingUnitAmount,
  insuranceIncomingUnitAmount,
  isExchange,
  wantsOutgoingInsurance,
  wantsIncomingInsurance,
  isLoading,
}) => {
  const { t } = useTranslation('purchase');
  const preWantsOutgoingInsurance = useRef(null);
  const preWantsIncomingInsurance = useRef(null);

  useEffect(() => {
    preWantsOutgoingInsurance.current = wantsOutgoingInsurance;
    preWantsIncomingInsurance.current = wantsIncomingInsurance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasIncomingInsurance = insuranceIncomingUnitAmount > 0;
  const departureInsuranceLabel = hasIncomingInsurance
    ? 'label.departing_trip'
    : 'label.add_trip_insurance';

  return (
    <div className="bg-white rounded-[10px] shadow-[0_1px_6px_rgba(35,35,35,0.15)] flex py-[15px] px-[10px] lg:p-[15px] cursor-pointer">
      <Spacing
        size="S"
        justifyContent="space-between"
        alignItems="center"
        mobileAlign={!hasIncomingInsurance ? 'center' : 'flex-start'}
        isResponsive={hasIncomingInsurance}
        fullWidth
      >
        <Spacing size="S" responsiveSize="XXS" alignItems="center" fullWidth>
          <Icon type={insuranceETN} mobileSize="S" />
          <InsuranceModal />
        </Spacing>

        <Spacing alignItems="center" justifyContent="space-between" fullWidth>
          {insuranceOutgoingUnitAmount > 0 && (
            <Field
              id={`${passenger}.wantsOutgoingInsurance`}
              name={`${passenger}.wantsOutgoingInsurance`}
              component={toggleRenderer}
              label={t(departureInsuranceLabel, {
                cost: formatCurrency(insuranceOutgoingUnitAmount, 2),
              })}
              disabled={isExchange && wantsOutgoingInsurance && preWantsOutgoingInsurance.current}
              isLoading={isLoading}
            />
          )}

          {hasIncomingInsurance && (
            <Field
              id={`${passenger}.wantsIncomingInsurance`}
              name={`${passenger}.wantsIncomingInsurance`}
              component={toggleRenderer}
              label={t('label.returning_trip', {
                cost: formatCurrency(insuranceIncomingUnitAmount, 2),
              })}
              disabled={isExchange && wantsOutgoingInsurance && preWantsIncomingInsurance.current}
              isLoading={isLoading}
            />
          )}
        </Spacing>
      </Spacing>
    </div>
  );
};

PassengerInsuranceToggle.propTypes = {
  passenger: PropTypes.string.isRequired,
  insuranceOutgoingUnitAmount: PropTypes.number.isRequired,
  insuranceIncomingUnitAmount: PropTypes.number,
  isExchange: PropTypes.bool,
  wantsOutgoingInsurance: PropTypes.bool,
  wantsIncomingInsurance: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

export default PassengerInsuranceToggle;
