import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * ManualInvoicing component
 *
 * This component displays a message with a contact email for manual invoicing.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.billingEmail - The billing email address
 * @returns {JSX.Element} The rendered component
 */
function ManualInvoicing({ billingEmail }) {
  const { t } = useTranslation('invoicing');

  return (
    <p className="text-gray-400 font-semibold">
      {t('manual.contact')}
      <a
        href={`mailto:${billingEmail}`}
        className="text-accent-regular font-bold text-base ml-1 hover:text-accent-strong"
        target="_blank"
        rel="noopener noreferrer"
      >
        {billingEmail}
      </a>
    </p>
  );
}

ManualInvoicing.propTypes = {
  billingEmail: PropTypes.string.isRequired,
};

export default ManualInvoicing;
