import React from 'react';
import 'styles/components/PoweredBy';

const PoweredBy = () => (
  <div className="powered-by">
    <i className="powered-by-reserhub" />
  </div>
);

export default PoweredBy;
